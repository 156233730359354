import React, {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import { CloseIcon } from 'components/icons'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { onEnterKeyPress } from 'utils/keyboard'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'
import { trackAiChatInputEntered, trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as StopIcon } from 'images/icon--stop.svg'
import { ReactComponent as UpArrow } from 'images/icon--up-arrow-send.svg'

import { useGlobalChat } from '../GlobalChatProvider'
import { useGlobalChatTracking } from '../GlobalChatTrackingProvider'
import { CommandListType } from '../types'

export const UserInput = ({
  autofocus,
  adjustableHeight = false
}: {
  autofocus: boolean
  adjustableHeight?: boolean
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()
  const { trackChatDraftStarted } = useGlobalChatTracking()

  const [input, setInput] = useState<string>('')
  const [showPopup, setShowPopup] = useState(false)
  const popupScrollableRef = useRef<null | HTMLDivElement>(null)
  const [commandItemInFocus, setCommandItemInFocus] = useState(0)
  const { chatId, isLoading, sendMessage, setMode, mode, stopGeneration } =
    useGlobalChat()
  const [activeTemplate, setActiveTemplate] = useState<string | null>(
    mode.modeOptions?.template_name
  )
  const [activeTemplateLabel, setActiveTemplateLabel] = useState(mode.modeOptions?.label)

  const { aiShowDraftCommands } = useFeatureFlags()

  const submitDisabled = !input || isLoading

  useEffect(
    function updateActiveTemplateOnModeChange() {
      setActiveTemplate(mode.modeOptions?.template_name)
      setActiveTemplateLabel(mode.modeOptions?.label)
    },
    [mode.modeOptions]
  )

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value)
  }, [])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (!input) {
        return
      }

      sendMessage(input, {
        mode: mode.mode,
        modeOptions: mode.modeOptions
      })

      setInput('')

      const textarea = document.getElementById(
        'ai-chat-global-input'
      ) as HTMLTextAreaElement | null
      if (textarea) textarea.setAttribute('style', '')
    },
    [input, sendMessage, mode]
  )

  const adjustHeight = () => {
    const textArea = textAreaRef.current
    if (textArea) {
      textArea.style.height = 'inherit'
      textArea.style.overflowY = 'hidden'
      textArea.style.height = `${textArea.scrollHeight}px`
      const maxHeight = 300

      if (textArea.scrollHeight > 300) {
        textArea.style.overflowY = 'visible'
        textArea.style.height = `${maxHeight}px`
      }
    }
  }

  useEffect(() => {
    if (adjustableHeight) adjustHeight()
  }, [adjustableHeight])

  const handleCommandSelect = useCallback(
    ({ command }: { command: CommandType }) => {
      setMode({
        mode: command.mode,
        modeOptions: command.modeOptions
      })

      setActiveTemplate(command.modeOptions.template_name)
      setActiveTemplateLabel(command.modeOptions.label)
      // maybe we only want  to conditionally send this message
      // e.g in existing chat with existing generated draft
      sendMessage(`Help me draft a ${command.label} document`, {
        mode: command.mode,
        modeOptions: command.modeOptions
      })
      setShowPopup(false)
      setInput('')

      trackChatDraftStarted({
        chatId: chatId,
        templateName: command.modeOptions.label,
        location: 'in_chat'
      })
    },
    [
      sendMessage,
      setActiveTemplate,
      setActiveTemplateLabel,
      setMode,
      setShowPopup,
      setInput,
      chatId,
      trackChatDraftStarted
    ]
  )

  const handleKeyCommands = (e: KeyboardEvent<HTMLElement>) => {
    // submit the form on enter
    if (e.key === 'Enter' && !e.shiftKey && !showPopup) {
      // prevent newline from being inserted, unless shift is pressed
      e.preventDefault()
      if (!submitDisabled) {
        trackAiChatInputEntered({
          chat_session_id: chatId,
          path: pathname,
          access_policy_kind: currentUser?.accessPolicyKind,
          trial_status: currentUser?.trialStatus,
          is_draft: mode.mode === 'document_generation',
          mode: mode.mode,
          draft_type:
            mode.mode === 'document_generation' ? mode.modeOptions.template_name : null
        })
        const fakeEvent = { preventDefault: () => {} }
        handleSubmit(fakeEvent as FormEvent<HTMLFormElement>)
      }
    }
    if (showPopup) {
      const selectedCommand = commandList[commandItemInFocus]
      if (e.key === 'Enter') {
        e.preventDefault()
        handleCommandSelect({ command: selectedCommand })
      }
      if (e.key === 'ArrowUp') {
        if (commandItemInFocus === 0) {
          setCommandItemInFocus(commandList.length - 1)
          if (popupScrollableRef.current) {
            popupScrollableRef.current.scrollTop = popupScrollableRef.current.scrollHeight
          }
        } else {
          setCommandItemInFocus(commandItemInFocus - 1)
          if (popupScrollableRef.current) popupScrollableRef.current.scrollTop -= 35
        }
      }
      if (e.key === 'ArrowDown') {
        if (commandItemInFocus === commandList.length - 1) {
          setCommandItemInFocus(0)
          if (popupScrollableRef.current) popupScrollableRef.current.scrollTop = 0
        } else {
          setCommandItemInFocus(commandItemInFocus + 1)
          if (popupScrollableRef.current) popupScrollableRef.current.scrollTop += 35
        }
      }
    }
  }

  const handleSpecialCharacters = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value === '@' && !activeTemplate && aiShowDraftCommands) {
      setShowPopup(true)
    } else if (e.target.value.indexOf('@') === -1) {
      // if @ is not in input, close the popup
      setShowPopup(false)
    }

    handleInputChange(e)
    if (adjustableHeight) adjustHeight()
  }

  const handleCommandClear = () => {
    setActiveTemplate(null)
    setActiveTemplateLabel(null)
    setMode({ mode: 'default', modeOptions: {} })
  }

  const formRef = useRef<HTMLFormElement>(null)

  return (
    <form
      ref={formRef}
      onSubmit={(args) => {
        trackCtaClicked({
          cta_location: 'AI_chat_global_modal',
          cta_type: 'button',
          text: 'ask_question',
          access_policy_kind: currentUser?.accessPolicyKind
        })
        handleSubmit(args)
      }}
      className="has-[:focus]:border-rb-gray-300 relative flex w-full shrink-0 flex-grow flex-col overflow-hidden rounded-xl border border-rb-gray-200 bg-white"
    >
      <div className="w-full flex flex-col">
        {activeTemplate && (
          <div className="flex flex-col divide-y overflow-hidden rounded-t-[20px] border-b border-rb-gray-100">
            <div className="flex items-center py-2.5 gap-4 pl-3 pr-4 text-sm">
              <span className="flex-1 line-clamp-3 py-0.5 font-semibold text-rb-gray-300">
                Drafting a {activeTemplateLabel}
              </span>
              <button
                className="flex-shrink-0 text-rb-gray-300"
                onClick={handleCommandClear}
              >
                <CloseIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        )}
        <Popover
          parentElement={formRef.current as HTMLElement}
          isOpen={showPopup}
          ref={textAreaRef}
          containerClassName="z-[1200] bg-white"
          content={
            <CommandPopup
              scrollableRef={popupScrollableRef}
              onSelect={handleCommandSelect}
              commandItemInFocus={commandItemInFocus}
            />
          }
          positions={['top']}
          onClickOutside={() => setShowPopup(false)}
          containerStyle={{ width: 'auto' }} // TODO: fix width. 100% doesn't work
        >
          <textarea
            id="ai-chat-global-input"
            className="box-border w-full resize-none rounded-xl py-3 pl-4 pr-10 text-base text-rb-black/85 outline-none md:pr-12"
            ref={textAreaRef}
            rows={1}
            placeholder="Send a message"
            name="message"
            key="message"
            value={input}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => handleSpecialCharacters(e)}
            onKeyDown={(e) => handleKeyCommands(e)}
            required
            autoFocus={autofocus}
          />
        </Popover>
      </div>
      {submitDisabled && isLoading ? (
        <Button
          className={cn(
            'absolute bottom-3 right-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-rb-gray-200 bg-white p-0 transition duration-200 ease-in-out hover:bg-rb-gray-50 md:right-3'
          )}
          data-dd-action-name="AI Sidekick stop inflight response CTA"
          variant="text-only"
          size="x-small"
          tabIndex={0}
          onKeyDown={onEnterKeyPress(stopGeneration)}
          onClick={(e) => {
            e.preventDefault()
            stopGeneration()
          }}
        >
          <StopIcon width={12} className="stroke-rb-gray-400" />
        </Button>
      ) : (
        <Button
          className={cn(
            'group absolute bottom-3 right-2 flex h-6 w-6 items-center justify-center rounded-md bg-rb-teal-600 p-0 hover:bg-rb-teal-600 hover:text-rb-white md:right-3',
            {
              'bg-rb-white hover:bg-rb-white': submitDisabled
            }
          )}
          dd-action-name="AI sidekick submit button"
          variant="text-only"
          size="x-small"
          type="submit"
          disabled={submitDisabled}
        >
          <UpArrow
            width={12}
            className={cn('stroke-white', {
              'stroke-rb-gray-200': submitDisabled
            })}
          />
        </Button>
      )}
    </form>
  )
}

const commandList = [
  {
    name: 'draft',
    label: 'PMF Narrative',
    mode: 'document_generation',
    modeOptions: {
      command: 'draft',
      template_name: 'ai-pmf-narrative-template-prompt',
      label: 'PMF Narrative'
    }
  },
  {
    name: 'draft',
    label: 'GTM Strategy',
    mode: 'document_generation',
    modeOptions: {
      command: 'draft',
      template_name: 'ai-gtm-strategy-template-prompt',
      label: 'GTM Strategy'
    }
  },
  {
    name: 'draft',
    label: 'PRD',
    mode: 'document_generation',
    modeOptions: {
      command: 'draft',
      template_name: 'ai-prd-template-prompt',
      label: 'PRD'
    }
  },
  {
    name: 'draft',
    label: 'Use Case Map',
    mode: 'document_generation',
    modeOptions: {
      command: 'draft',
      template_name: 'ai-use-case-map-template-prompt',
      label: 'Use Case Map'
    }
  },
  {
    name: 'draft',
    label: 'User Test Plan',
    mode: 'document_generation',
    modeOptions: {
      command: 'draft',
      template_name: 'ai-user-test-plan-template-prompt',
      label: 'User Test Plan'
    }
  },
  {
    name: 'draft',
    label: 'Feature Map',
    mode: 'document_generation',
    modeOptions: {
      command: 'draft',
      template_name: 'ai-feature-map-template-prompt',
      label: 'Feature Map'
    }
  }
] as CommandListType

// NOTE: We should feel free to change this data structure
// this is just a starting point
type CommandType = {
  name: string
  label: string
  mode: string
  modeOptions: Record<string, any>
}
const CommandPopup = ({
  onSelect,
  commandItemInFocus,
  scrollableRef
}: {
  onSelect: ({ command }: { command: CommandType }) => void
  commandItemInFocus: number
  scrollableRef: MutableRefObject<null | HTMLDivElement>
}) => {
  return (
    <div className="space-y-2 z-20 w-[350px]">
      <div className="popover rounded-2xl border p-2 shadow-lg">
        <div ref={scrollableRef} className="max-h-40 overflow-y-auto gap-2 flex flex-col">
          {commandList.map((command: CommandType, index: number) => (
            <CommandItem
              key={index}
              idx={index}
              onSelect={() => onSelect({ command })}
              name={command.name}
              label={command.label}
              highlighted={commandItemInFocus === index}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const CommandItem = ({
  onSelect,
  name,
  label,
  idx,
  highlighted
}: {
  onSelect: () => void
  name: string
  label: string
  idx: number
  highlighted: boolean
}) => {
  return (
    <div
      id={`command-item-${idx}`}
      tabIndex={0}
      onClick={onSelect}
      onKeyUp={onEnterKeyPress(onSelect)}
      role="button"
      className="cursor-pointer group hover:bg-gray-100"
    >
      <div
        className={twMerge(
          'h-10 rounded-lg py-2 px-3 font-normal overflow-hidden text-ellipsis whitespace-nowrap',
          highlighted ? 'bg-rb-gray-50' : ''
        )}
      >
        <span className="flex-grow truncate">
          {capitalizeFirstLetter(name)} a {label}
        </span>
      </div>
    </div>
  )
}
