import { cn } from 'utils/tailwind'

import { USER_MESSAGE_BUBBLE_CLASSES } from '../Chat/helpers'
import { useGlobalChat } from '../GlobalChatProvider'
import { ButtonPrompt, SuggestedPromptsLoading } from './SuggestedPrompts'

export const InChatSuggestedPrompts = () => {
  const {
    isLoading: loadingMessages,
    sendMessage,
    mode,
    suggestedPrompts,
    suggestedPromptsLoading
  } = useGlobalChat()

  const className = cn(
    USER_MESSAGE_BUBBLE_CLASSES,
    'ml-0 cursor-pointer rounded-lg border border-rb-teal-50 bg-rb-teal-50 bg-opacity-40 transition duration-200 ease-in-out hover:bg-rb-teal-50 hover:opacity-100'
  )

  if (loadingMessages) return null

  // NOTE: for now we're assuming that unless in default mode, we don't want to show suggested prompts
  const isInDefaultMode = mode.mode === 'default'
  if (!isInDefaultMode) return null

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-2 overflow-hidden">
      {suggestedPromptsLoading ? (
        <SuggestedPromptsLoading />
      ) : (
        suggestedPrompts.map((prompt) => (
          <ButtonPrompt
            key={`${prompt}`}
            onClick={() => sendMessage(prompt, { isSuggestedFollowup: true })}
            className={className}
          >
            {prompt}
          </ButtonPrompt>
        ))
      )}
    </div>
  )
}
