import React, { Fragment, useCallback, useMemo } from 'react'
import ScrollToBottom from 'react-scroll-to-bottom'

import { Loading } from 'components'
import DoptPanel from 'components/dopt/DoptPanel'

import { cn } from 'utils/tailwind'

import { InChatSuggestedPrompts } from '../SuggestedPrompts'
import { CopyableProvider } from '../hooks/useCopyable'
import { Message } from '../types'
import { Feedback } from './Feedback'
import { MessageCard } from './MessageCard'
import ScrollToBottomButton from './ScrollToBottomButton'
import { ASSISTANT_MESSAGE_BUBBLE_CLASSES } from './helpers'
import { useDelayMessageTimer } from './useDelayMessageTimer'

export interface ChatFeedProps {
  chatId: string
  messages: Message[]
  isLoading: boolean
  loadingSession: boolean
  reload: () => void
  onSourceLinkClick: () => void
  isFreeUser: boolean
  draftTemplateName?: string
  onGeneratedDraftCtaClick: ({
    message,
    htmlString,
    title
  }: {
    message: Message
    htmlString: string
    title: string
  }) => void
}

const MessageCardMemoized = React.memo(MessageCard)

const NUM_USER_MESSAGES_AFTER_COPILOT_CTA_SHOWN = 3

export const ChatFeed = React.memo(function ChatFeed({
  chatId,
  messages,
  isLoading,
  loadingSession,
  reload,
  onSourceLinkClick,
  isFreeUser,
  draftTemplateName,
  onGeneratedDraftCtaClick
}: ChatFeedProps) {
  const numberUserMessages = useMemo(
    () => messages.filter((m) => m.role === 'user').length,
    [messages]
  )

  const showInlineCopilotCta = useCallback(
    (index: number) => {
      return (
        !isFreeUser &&
        numberUserMessages > NUM_USER_MESSAGES_AFTER_COPILOT_CTA_SHOWN &&
        messages[index].role !== 'user' &&
        messages.slice(0, index + 1).filter((m) => m.role === 'user').length ===
          NUM_USER_MESSAGES_AFTER_COPILOT_CTA_SHOWN
      )
    },
    [messages, numberUserMessages, isFreeUser]
  )

  const showEndCopilotCta = useCallback(
    () =>
      !isFreeUser &&
      !isLoading &&
      numberUserMessages === NUM_USER_MESSAGES_AFTER_COPILOT_CTA_SHOWN,
    [isLoading, numberUserMessages, isFreeUser]
  )

  const { showDelayMessage } = useDelayMessageTimer({ messages, isLoading })

  if (loadingSession) return <Loading />

  return (
    <ScrollToBottom
      className="h-full grow"
      followButtonClassName="hidden"
      scrollViewClassName="hide-scrollbar"
    >
      <ScrollToBottomButton />
      <div
        id="ai-chat-global-messages"
        className="hide-scrollbar flex flex-col gap-6 overflow-y-auto h-full"
      >
        <PreambleDefault />
        {messages.map((message, index) => {
          const isLastMessage = index === messages.length - 1
          const shouldShowMessageActions =
            message.role === 'assistant' &&
            !message.isPredefined &&
            !message.documents?.draft &&
            !isLoading

          return (
            <Fragment key={message.id}>
              <CopyableProvider>
                <MessageCardMemoized
                  key={message.id}
                  chatId={chatId}
                  message={message}
                  onSourceLinkClick={onSourceLinkClick}
                  onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                  isLoading={isLoading}
                  isLast={isLastMessage}
                />
                {shouldShowMessageActions && (
                  <Feedback
                    onReload={reload}
                    allowReload={isLastMessage && message.role === 'assistant'}
                    chatId={chatId}
                    message={message}
                    draftTemplateName={draftTemplateName}
                  />
                )}
              </CopyableProvider>
              {showInlineCopilotCta(index) && (
                <DoptPanel blockIdentifier="copilot-cta-panel.in-chat-panel" />
              )}
            </Fragment>
          )
        })}
        {showDelayMessage && (
          <div className="flex justify-center">
            <span>⏳ Response generation in progress. Thanks for your patience! ...</span>
          </div>
        )}
        <div className="mt-auto">
          <InChatSuggestedPrompts />
        </div>

        {showEndCopilotCta() && (
          <DoptPanel blockIdentifier="copilot-cta-panel.in-chat-panel" className="mt-4" />
        )}
      </div>
    </ScrollToBottom>
  )
})

const PreambleDefault = () => (
  <div className={cn(ASSISTANT_MESSAGE_BUBBLE_CLASSES, 'shrink-0 cursor-default')}>
    <div className="font-bold">An AI-powered expert by your side</div>
    <div className="break-words">
      {
        "Your partner in problem-solving. Share your challenges and get tailored advice, all rooted in Reforge's expert content. Need a starting point? Try these prompts:"
      }
    </div>
  </div>
)
