import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

interface DoptCalloutProps {
  position:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topRight'
    | 'topLeft'
    | 'bottomRight'
    | 'bottomLeft'
  blockArray: any
  onComplete?: () => void
}

const arrowPositionMap = {
  bottom: 'left-[138px] top-[-7px]',
  top: 'left-[138px] bottom-[-7px] transform rotate-180',
  right: 'top-1/2 left-[-14px] transform -translate-y-1/2 -rotate-90',
  left: 'top-1/2 right-[-14px] transform -translate-y-1/2 rotate-90',
  topRight: 'left-[20px] bottom-[-7px] transform rotate-180',
  topLeft: 'right-[20px] bottom-[-7px] transform rotate-180',
  bottomRight: 'left-[20px] top-[-7px]',
  bottomLeft: 'right-[20px] top-[-7px]'
}

const calloutPositionMap = {
  bottom: { top: 'calc(100% + 8px)', left: '50%', transform: 'translateX(-50%)' },
  top: { bottom: 'calc(100% + 8px)', left: '50%', transform: 'translateX(-50%)' },
  right: { left: 'calc(100% + 8px)', top: '50%', transform: 'translateY(-50%)' },
  left: { right: 'calc(100% + 8px)', top: '50%', transform: 'translateY(-50%)' },
  topRight: { bottom: 'calc(100% + 8px)', left: '-15px' },
  topLeft: { bottom: 'calc(100% + 8px)', right: '-15px' },
  bottomLeft: { top: 'calc(100% + 8px)', right: '-15px' },
  bottomRight: { top: 'calc(100% + 8px)', left: '-15px' }
}

const CalloutArrow = ({
  position
}: {
  position:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topRight'
    | 'topLeft'
    | 'bottomRight'
    | 'bottomLeft'
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="12"
      viewBox="0 0 23 12"
      fill="none"
      className={twMerge('absolute', arrowPositionMap[position])}
    >
      <path d="M22.6274 11.6855L11.3137 0.371839L0 11.6855H22.6274Z" fill="#2D2F2F" />
      <rect width="23" height="3" transform="matrix(1 0 0 -1 0 12)" fill="#2D2F2F" />
    </svg>
  )
}

const DoptCallout = ({ position, blockArray, onComplete }: DoptCalloutProps) => {
  const [block, transition] = blockArray
  const [showCallout, setShowCallout] = useState(!block.field('delay'))

  useEffect(() => {
    if (block.field('delay')) {
      const timeout = setTimeout(() => {
        setShowCallout(true)
      }, block.field('delay'))
      return () => clearTimeout(timeout)
    }
  }, [block])

  if (!block?.state.active || !showCallout) {
    return null
  }

  const handleComplete = () => {
    onComplete?.()
    transition('complete')
  }

  return (
    <div className="absolute z-1000" style={calloutPositionMap[position]}>
      <div className="relative flex w-[300px] flex-col gap-2 rounded-xl bg-rb-gray-400 p-6 text-xl font-semibold text-white shadow-default">
        <span>{block.field('content')}</span>
        <div className="flex justify-end gap-2">
          {!!block.field('total-steps') && block.field('step-num') !== '1' && (
            <Button
              size="medium"
              variant="text-only"
              className="text-white hover:bg-inherit hover:text-rb-gray-100"
              onClick={() => transition('previous')}
            >
              Back
            </Button>
          )}
          <Button size="medium" variant="outline" onClick={handleComplete}>
            {block.field('button-text')}
          </Button>
        </div>
        {!!block.field('total-steps') && (
          <div className="flex justify-center gap-2">
            {Array.from({ length: Number(block.field('total-steps')) }).map(
              (_, index) => (
                <div
                  key={index}
                  className={`h-3 w-3 rounded-full ${
                    index + 1 === block.field('step-num')
                      ? 'bg-rb-green-100'
                      : 'bg-[#B5B8B4]'
                  }`}
                />
              )
            )}
          </div>
        )}
        <CalloutArrow position={position} />
      </div>
    </div>
  )
}

export default DoptCallout
