import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  useArtifactPageQuery,
  useCourseInfoForTrackingQuery,
  useCourseSessionInfoForTrackingQuery,
  useLessonViewerQuery,
  useProgramSummaryQuery,
  useSanityUnitQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  contentModeFromCourseSession,
  contentTypeFromPathname,
  isOnArtifactPage,
  isOnCoursePage,
  isOnGuidePage,
  isOnLessonPage,
  isOnProgramPage,
  parseCoursePageUrl
} from 'utils/contentTrackingUtils'
import {
  trackAiChatAutoRetry,
  trackAiChatClosed,
  trackAiChatDraftCreated,
  trackAiChatDraftOpened,
  trackAiChatDraftStarted,
  trackAiChatExpanded,
  trackAiChatFeedbackClicked,
  trackAiChatRetryClicked,
  trackAiChatSessionOpened,
  trackAiChatSuggestionClicked,
  trackCopiedText
} from 'utils/tracking/analytics'
import { LESSON_VIEWER_URL } from 'utils/url'
import { parseArtifactSlug, parseGuideSlug, parseProgramSlug } from 'utils/urlUtils'

type TrackChatOpenedParams = {
  chatId: string
  ctaText?: string
  pageLocation?: string
  isDraft?: boolean
  draftType?: string
  isSuggestedPrompt?: boolean
  chatSessionType: 'new' | 'existing'
  mode: string
}

type TrackChatCopiedTextParams = {
  chatId: string
  text: string
  citedSourcesCount: number
  messageId: string
  draftTemplateName?: string
}

type GlobalChatTrackingContextType = {
  trackChatOpened: (params: TrackChatOpenedParams) => void
  trackChatClosed: ({ chatId, mode }: { chatId: string; mode: string }) => void
  trackChatSuggestionClicked: ({ chatId }: { chatId: string }) => void
  trackChatAutoRetry: ({
    chatId,
    retryCount
  }: {
    chatId: string
    retryCount: number
  }) => void
  trackChatRetryClicked: ({ chatId, mode }: { chatId: string; mode: string }) => void
  trackChatCopiedText: ({
    chatId,
    text,
    citedSourcesCount,
    messageId
  }: TrackChatCopiedTextParams) => void
  trackChatFeedbackClicked: ({
    chatId,
    feedbackValue
  }: {
    chatId: string
    feedbackValue: 'positive' | 'negative'
  }) => void
  trackChatDraftStarted: ({
    chatId,
    templateName,
    location
  }: {
    chatId: string
    templateName: string
    location: string
  }) => void
  trackChatExpanded: ({ chatId, mode }: { chatId: string; mode: string }) => void
  trackChatDraftOpened: ({
    chatId,
    templateName
  }: {
    chatId: string
    templateName: string
  }) => void
  trackChatDraftCreated: ({
    chatId,
    templateName
  }: {
    chatId: string
    templateName: string
  }) => void
}

const GlobalChatTrackingContext = createContext<GlobalChatTrackingContextType | null>(
  null
)

export const GlobalChatTrackingProvider = ({
  children,
  isLoggedIn
}: {
  children: React.ReactNode
  isLoggedIn: boolean
}) => {
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()

  // Guides
  const guideSlug = parseGuideSlug(pathname)
  const { data: guideData } = useSanityUnitQuery({
    variables: { slug: guideSlug },
    skip: !isOnGuidePage(pathname)
  })

  // Courses
  const {
    courseSlug,
    courseSessionId,
    contentMode: contentModeFromPath
  } = parseCoursePageUrl(pathname) || {}

  const { data: courseInfoData } = useCourseInfoForTrackingQuery({
    variables: { slug: courseSlug! }, // we skip query if no slug, so we can force the type
    skip: !isOnCoursePage(pathname) || !isLoggedIn
  })

  const { data: courseSessionInfoData } = useCourseSessionInfoForTrackingQuery({
    variables: { courseSessionId: courseSessionId! }, // we skip query if no courseSessionId, so we can force the type
    skip: !courseSessionId
  })

  const contentMode = contentModeFromCourseSession(courseSessionInfoData?.courseSession)

  // Artifacts
  const artifactSlug = parseArtifactSlug(pathname)

  const { data: artifactData } = useArtifactPageQuery({
    variables: { slug: artifactSlug },
    skip: !isOnArtifactPage(pathname)
  })

  // Lessons
  const lessonUrlData = useMemo(() => {
    const match = matchPath<{
      cmsProgramSlug: string
      cmsModuleSlug: string
      cmsSectionParentSlug: string
      cmsSectionSlug: string
    }>(pathname, { path: LESSON_VIEWER_URL })
    return {
      cmsProgramSlug: match?.params.cmsProgramSlug || '',
      cmsModuleSlug: match?.params.cmsModuleSlug || '',
      cmsSectionSlug: match?.params.cmsSectionSlug || '',
      cmsSectionParentSlug: match?.params.cmsSectionParentSlug,
      path: pathname
    }
  }, [pathname])
  const { data: lessonViewerData } = useLessonViewerQuery({
    variables: {
      cmsProgramSlug: lessonUrlData?.cmsProgramSlug,
      cmsModuleSlug: lessonUrlData?.cmsModuleSlug,
      cmsSectionParentSlug: lessonUrlData?.cmsSectionParentSlug,
      cmsSectionSlug: lessonUrlData?.cmsSectionSlug,
      path: pathname
    },
    skip: !isOnLessonPage(pathname)
  })
  // Programs
  const programSlug = parseProgramSlug(pathname)

  const { data: programSummaryData } = useProgramSummaryQuery({
    variables: { slug: programSlug },
    skip: !isOnProgramPage(pathname) || !programSlug
  })

  const contentType = contentTypeFromPathname(pathname)
  const onContentPage = !!contentType

  const contentId = onContentPage
    ? {
        guide: guideData?.sanityUnit?.id,
        course: courseInfoData?.course?.id,
        artifact: artifactData?.artifact?.id,
        lesson: lessonViewerData?.lessonViewer?.cmsSection?.id,
        program: programSummaryData?.cmsProgram?.id
      }[contentType]
    : null

  const trackChatOpened = useCallback(
    ({
      ctaText,
      pageLocation,
      isSuggestedPrompt,
      chatSessionType,
      isDraft,
      draftType,
      chatId,
      mode
    }: TrackChatOpenedParams) => {
      const eventData = {
        // Always Track
        access_policy_kind: currentUser?.accessPolicyKind, //                      The user's access policy
        chat_session_id: chatId, //                                                id of the chat that is being opened/created
        chat_session_type: chatSessionType, //                                     loading an old chat or starting a new one
        location: pathname, //                                                     pathname of the current page
        path: pathname, //                                                         pathname of the current page
        text: ctaText, //                                                          CTA that led to chat open ('Reforge AI', suggested prompt text, etc.)
        mode,

        // Suggested Prompts entry-point
        page_location: pageLocation, //                                            Where on the page the CTA lived (used for suggested prompts)
        is_suggested_prompt: !!isSuggestedPrompt, //                               Whether or not the chat is being opened by clicking a suggested prompt
        is_draft: isDraft, //                                                      Whether the chat started is a draft

        // Track on content pages
        draft_type: draftType, //                                                   The type of draft created to start the chat
        content_type: contentType, //                                              The type of content if user is on content page
        content_id: contentId, //                                                  The id for the content a user is on page for
        related_identifiers: isOnCoursePage(pathname)
          ? {
              content_mode: (contentModeFromPath || contentMode) as string, //     Viewing mode for course content
              course_id: courseInfoData?.course?.id, //                            Id of course if on course page
              course_title: courseInfoData?.course?.title, //                      title of course if on course page
              course_session_id: courseSessionInfoData?.courseSession?.id //       session id of course if on course page
            }
          : {},
        trial_status: currentUser?.trialStatus //                                  The status of the user's free trial, if applicable
      }

      trackAiChatSessionOpened(eventData)
    },
    [
      pathname,
      contentId,
      contentType,
      contentMode,
      contentModeFromPath,
      courseInfoData,
      courseSessionInfoData,
      currentUser?.accessPolicyKind,
      currentUser?.trialStatus
    ]
  )

  const trackChatClosed = useCallback(
    ({ chatId, mode }: { chatId: string; mode: string }) => {
      trackAiChatClosed({
        chat_session_id: chatId,
        path: pathname,
        access_policy_kind: currentUser?.accessPolicyKind,
        mode
      })
    },
    [pathname, currentUser?.accessPolicyKind]
  )
  const trackChatSuggestionClicked = useCallback(
    ({ chatId }: { chatId: string }) => {
      trackAiChatSuggestionClicked({
        chat_session_id: chatId,
        path: pathname,
        access_policy_kind: currentUser?.accessPolicyKind,
        trial_status: currentUser?.trialStatus
      })
    },
    [pathname, currentUser?.accessPolicyKind, currentUser?.trialStatus]
  )

  const trackChatAutoRetry = useCallback(
    ({ chatId, retryCount }: { chatId: string; retryCount: number }) => {
      trackAiChatAutoRetry({
        chat_session_id: chatId,
        retry_count: retryCount,
        access_policy_kind: currentUser?.accessPolicyKind
      })
    },
    [currentUser?.accessPolicyKind]
  )

  const trackChatRetryClicked = useCallback(
    ({ chatId, mode }: { chatId: string; mode: string }) => {
      trackAiChatRetryClicked({
        chat_session_id: chatId,
        path: pathname,
        access_policy_kind: currentUser?.accessPolicyKind,
        mode
      })
    },
    [pathname, currentUser?.accessPolicyKind]
  )

  const trackChatCopiedText = useCallback(
    ({
      chatId,
      text,
      citedSourcesCount,
      messageId,
      draftTemplateName
    }: {
      chatId: string
      text: string
      citedSourcesCount: number
      messageId: string
      draftTemplateName?: string
    }) => {
      trackCopiedText({
        object_id: chatId,
        object_type: draftTemplateName ? 'ai_draft' : 'ai_chat_session',
        length: text.length,
        related_identifiers: {
          cited_source_count: citedSourcesCount,
          copied_message_id: messageId,
          draftType: draftTemplateName
        }
      })
    },
    []
  )

  const trackChatFeedbackClicked = useCallback(
    ({
      chatId,
      feedbackValue
    }: {
      chatId: string
      feedbackValue: 'positive' | 'negative'
    }) => {
      trackAiChatFeedbackClicked({
        chat_session_id: chatId,
        feedback_value: feedbackValue,
        path: pathname
      })
    },
    [pathname]
  )

  const trackChatDraftStarted = useCallback(
    ({
      chatId,
      templateName,
      location
    }: {
      chatId: string
      templateName: string
      location: string
    }) => {
      trackAiChatDraftStarted({
        chat_session_id: chatId,
        content_id: contentId,
        draft_type: templateName,
        location: location,
        path: pathname,
        content_type: contentType,
        related_identifiers: isOnCoursePage(pathname)
          ? {
              content_mode: (contentModeFromPath || contentMode) as string, //     Viewing mode for course content
              course_id: courseInfoData?.course?.id, //                            Id of course if on course page
              course_title: courseInfoData?.course?.title, //                      title of course if on course page
              course_session_id: courseSessionInfoData?.courseSession?.id //       session id of course if on course page
            }
          : {}
      })
    },
    [
      pathname,
      contentId,
      contentType,
      contentModeFromPath,
      contentMode,
      courseInfoData,
      courseSessionInfoData
    ]
  )

  const trackChatExpanded = useCallback(
    ({ chatId, mode }: { chatId: string; mode: string }) => {
      trackAiChatExpanded({
        chat_session_id: chatId,
        path: pathname,
        access_policy_kind: currentUser?.accessPolicyKind,
        mode
      })
    },
    [pathname, currentUser?.accessPolicyKind]
  )

  const trackChatDraftOpened = useCallback(
    ({ chatId, templateName }: { chatId: string; templateName: string }) => {
      trackAiChatDraftOpened({
        chat_session_id: chatId,
        draft_type: templateName
      })
    },
    []
  )

  const trackChatDraftCreated = useCallback(
    ({ chatId, templateName }: { chatId: string; templateName: string }) => {
      trackAiChatDraftCreated({
        chat_session_id: chatId,
        draft_type: templateName
      })
    },
    []
  )

  const value = useMemo(
    () => ({
      trackChatOpened,
      trackChatClosed,
      trackChatSuggestionClicked,
      trackChatAutoRetry,
      trackChatRetryClicked,
      trackChatCopiedText,
      trackChatFeedbackClicked,
      trackChatDraftStarted,
      trackChatExpanded,
      trackChatDraftOpened,
      trackChatDraftCreated
    }),
    [
      trackChatOpened,
      trackChatClosed,
      trackChatSuggestionClicked,
      trackChatAutoRetry,
      trackChatRetryClicked,
      trackChatCopiedText,
      trackChatFeedbackClicked,
      trackChatDraftStarted,
      trackChatExpanded,
      trackChatDraftOpened,
      trackChatDraftCreated
    ]
  )

  return (
    <GlobalChatTrackingContext.Provider value={value}>
      {children}
    </GlobalChatTrackingContext.Provider>
  )
}

export const useGlobalChatTracking = () => {
  const context = useContext(GlobalChatTrackingContext)
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider')
  }
  return context
}
