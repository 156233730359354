import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Editor } from 'domains/Ai/Chat/Editor/Editor'

import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import MembershipCallout from 'components/dopt/MembershipCallout'
import { BasicInfoIcon, CloseIcon, CopyIcon } from 'components/icons'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import {
  trackAiChatExpanded,
  trackAiChatMinimized as trackAiChatShrinked,
  trackCopiedText
} from 'utils/tracking/analytics'

import { ChatFeed } from './Chat/ChatFeed'
import { DraggableChatWrapper } from './Chat/DraggableChatWrapper'
import { MenuSideBar } from './Chat/MenuSideBar'
import PersonalizationCta from './Chat/PersonalizationCta'
import { Personalize, usePersonalizationConfirmationModal } from './Chat/Personalize'
import { UserInput } from './Chat/UserInput'
import { useGlobalChat } from './GlobalChatProvider'
import { Message } from './types'

const NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN = 3

export function GlobalChat() {
  const { pathname } = useLocation()
  const isMobile = useMediaQuery('(max-width: 671px)')
  const { currentUser } = useCurrentUser()

  const {
    chatId,
    isChatOpen,
    messages,
    isExpanded,
    setIsExpanded,
    minimize,
    endSession,
    loadSession,
    editor,
    editorHeaderTitle,
    setEditorHeaderTitle,
    showEditDraftView,
    setShowEditDraftView,
    resetMode,
    dedupedTopicsAndFunctions,
    dedupedTopicsAndFunctionsLoading,
    personalizationData,
    personalizationLoading,
    menuSideBarIsOpen,
    setMenuSideBarIsOpen,
    populateEditor,
    personalizeActive,
    setPersonalizeActive,
    messageDraftBeingEdited,
    clearAndCloseEditDraftView,
    mode
  } = useGlobalChat()

  const [personalizationFormIsDirty, setPersonalizationFormIsDirty] = useState(false)

  const isFullScreen = isExpanded || isMobile

  const { requestConfirmation } = usePersonalizationConfirmationModal()

  const isFreeUser = currentUser?.is.freeUser ?? false

  const onNewChatClick = useCallback(() => {
    function resetChat() {
      if (!isFullScreen || isMobile) setMenuSideBarIsOpen(false)
      if (messages.length > 0) endSession()
      setPersonalizeActive(false)
      resetMode()
    }

    if (personalizationFormIsDirty) {
      requestConfirmation(() => {
        setPersonalizationFormIsDirty(false)
        resetChat()
        setShowEditDraftView(false)
      })
    } else {
      resetChat()
      setShowEditDraftView(false)
    }
  }, [
    messages.length,
    personalizationFormIsDirty,
    isFullScreen,
    isMobile,
    endSession,
    requestConfirmation,
    setShowEditDraftView,
    resetMode,
    setMenuSideBarIsOpen,
    setPersonalizeActive
  ])

  const handleMinimize = useCallback(() => {
    if (personalizationFormIsDirty) {
      requestConfirmation(() => {
        setPersonalizationFormIsDirty(false)
        setPersonalizeActive(false)
        minimize()
        setIsExpanded(false)
        setMenuSideBarIsOpen(false)
      })
    } else {
      minimize()
      setPersonalizeActive(false)
      setIsExpanded(false)
      setMenuSideBarIsOpen(false)
    }

    // always close the edit draft view on minimize
    setShowEditDraftView(false)
    setEditorHeaderTitle('')
  }, [
    minimize,
    setIsExpanded,
    requestConfirmation,
    personalizationFormIsDirty,
    setShowEditDraftView,
    setEditorHeaderTitle,
    setMenuSideBarIsOpen,
    setPersonalizeActive
  ])

  const handleExpand = useCallback(() => {
    setIsExpanded(true)
    trackAiChatExpanded({
      chat_session_id: chatId,
      path: pathname,
      access_policy_kind: currentUser?.accessPolicyKind,
      mode: mode.mode
    })

    setMenuSideBarIsOpen(true)
  }, [
    setIsExpanded,
    chatId,
    pathname,
    currentUser?.accessPolicyKind,
    setMenuSideBarIsOpen,
    mode
  ])

  const onGeneratedDraftCtaClick = ({
    message,
    title,
    htmlString
  }: {
    message: Message
    htmlString: string
    title: string
  }) => {
    populateEditor({ message, title, htmlString })
  }

  const handlePersonalizationCancel = () => {
    if (personalizationFormIsDirty) {
      requestConfirmation(() => {
        setPersonalizeActive(false)
        setPersonalizationFormIsDirty(false)
      })
      return
    }

    if (!isFullScreen) {
      setMenuSideBarIsOpen(true)
    }

    setPersonalizeActive(false)
  }

  const handlePersonalizationSave = () => {
    setPersonalizeActive(false)
    setPersonalizationFormIsDirty(false)
  }

  const Layout = showEditDraftView ? DraftGenerationLayout : ChatViewLayout

  if (!isChatOpen || !currentUser) {
    return null
  }

  return (
    <div id="ai-chat-container">
      <DraggableChatWrapper
        isFullScreen={isFullScreen}
        showHeaderMenu={true}
        onExpand={handleExpand}
        startNewChat={onNewChatClick}
        onShrink={() => {
          setIsExpanded(false)
          setMenuSideBarIsOpen(false)

          // To avoid UI weirdness in showing editor in small view
          // we just hide the editor when shrinking
          setShowEditDraftView(false)

          trackAiChatShrinked({
            chat_session_id: chatId,
            path: pathname,
            access_policy_kind: currentUser?.accessPolicyKind
          })
        }}
        onClose={handleMinimize}
        onMenuSideBarOpen={() => {
          if (personalizeActive && !isFullScreen && personalizationFormIsDirty) {
            requestConfirmation(() => {
              setPersonalizeActive(false)
              setPersonalizationFormIsDirty(false)
              setMenuSideBarIsOpen(true)
            })
          } else {
            setMenuSideBarIsOpen(true)
          }
        }}
        onMenuSideBarClose={() => setMenuSideBarIsOpen(false)}
        menuSideBarIsOpen={menuSideBarIsOpen}
      >
        {menuSideBarIsOpen && (
          <div
            className={
              isFullScreen && !isMobile
                ? 'border-gray relative h-full w-72 border-r'
                : 'relative h-full w-full'
            }
          >
            <MenuSideBar
              personalizeActive={personalizeActive}
              onSetPersonalizeActive={() => {
                if (!isFullScreen || isMobile) {
                  setMenuSideBarIsOpen(false)
                }
                setPersonalizeActive(true)
              }}
              onExistingChatClick={(chatId) => {
                if (personalizationFormIsDirty) {
                  requestConfirmation(() => {
                    setPersonalizeActive(false)
                    setPersonalizationFormIsDirty(false)
                    loadSession(chatId)
                    if (!isFullScreen || isMobile) {
                      setMenuSideBarIsOpen(false)
                    }
                  })
                } else {
                  setPersonalizeActive(false)
                  loadSession(chatId)
                  if (!isFullScreen || isMobile) {
                    setMenuSideBarIsOpen(false)
                  }
                }
              }}
            />
            <div className="absolute bottom-0 left-0 right-0 flex h-12 items-center bg-white px-6 text-xs text-rb-gray-300">
              <a
                href="https://reforge.helpscoutdocs.com/article/191-reforge-ai"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about Reforge AI
              </a>
              <BasicInfoIcon className="ml-2 fill-rb-gray-300" fill="#757B74" />
            </div>
          </div>
        )}
        {(!menuSideBarIsOpen || (isFullScreen && !isMobile)) && (
          <Layout>
            {personalizeActive ? (
              <Personalize
                onCancel={handlePersonalizationCancel}
                onSave={handlePersonalizationSave}
                formIsDirty={personalizationFormIsDirty}
                setFormIsDirty={setPersonalizationFormIsDirty}
                personalizationData={personalizationData?.aiPersonalization}
                loading={dedupedTopicsAndFunctionsLoading || personalizationLoading}
                dedupedTopicsAndFunctions={
                  dedupedTopicsAndFunctions?.dedupedTopicsAndFunctions || []
                }
              />
            ) : (
              <>
                {showEditDraftView ? (
                  <DraftGenerationView
                    headerTitle={editorHeaderTitle}
                    onCopy={() => {
                      const htmlContent = editor?.getHTML() ?? ''
                      const plainText = editor?.getText() ?? ''

                      const clipboardItem = new ClipboardItem({
                        'text/html': new Blob([htmlContent], { type: 'text/html' }),
                        'text/plain': new Blob([plainText], { type: 'text/plain' })
                      })
                      navigator.clipboard.write([clipboardItem])

                      trackCopiedText({
                        object_type: 'ai_draft',
                        related_identifiers: {
                          chat_session_id: chatId,
                          draft_type: messageDraftBeingEdited?.modeOptions?.label
                        }
                      })

                      displayToast(
                        <ToastCard type="success" message="Copied to clipboard" />,
                        {
                          ...toastOptions,
                          toastId: 'reforge-toast'
                        }
                      )
                    }}
                    onClose={clearAndCloseEditDraftView}
                    isFreeUser={isFreeUser}
                    handleMinimize={handleMinimize}
                    onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                  />
                ) : (
                  <ChatContent
                    isFreeUser={isFreeUser}
                    handleMinimize={handleMinimize}
                    onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
                  />
                )}
              </>
            )}
          </Layout>
        )}
      </DraggableChatWrapper>
    </div>
  )
}

const DraftGenerationLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="h-full w-full p-4 pb-6">
      <div className="flex h-full flex-row justify-center">
        <div className="flex h-full w-full flex-row gap-4 break-words">{children}</div>
      </div>
    </div>
  )
}

const ChatViewLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="h-full w-full p-4 pb-6">
      <div className="flex h-full flex-row justify-center">
        <div className="flex h-full w-full flex-col gap-4 break-words md:max-w-4xl">
          {children}
        </div>
      </div>
    </div>
  )
}

interface ChatContentProps {
  isFreeUser: boolean
  handleMinimize: () => void
  onGeneratedDraftCtaClick: ({
    message,
    htmlString,
    title
  }: {
    message: Message
    htmlString: string
    title: string
  }) => void
}

const ChatContent: React.FC<ChatContentProps> = ({
  handleMinimize,
  onGeneratedDraftCtaClick,
  isFreeUser
}) => {
  const {
    chatId,
    messages,
    isLoading,
    loadingSession,
    reload,
    recentChatsCount,
    showPersonalizationCta,
    dismissPersonalizationCta,
    toggle,
    setIsExpanded,
    setPersonalizeActive,
    mode
  } = useGlobalChat()

  const limitExceeded = useMemo(
    () => recentChatsCount >= NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN && isFreeUser,
    [recentChatsCount, isFreeUser]
  )

  const isMobile = useMediaQuery('(max-width: 671px)')

  const onSourceLinkClick = useCallback(() => {
    if (isMobile) {
      toggle({})
    }
    setIsExpanded(false)
  }, [setIsExpanded, isMobile, toggle])

  return (
    <>
      <div className="hide-scrollbar h-full grow overflow-y-auto">
        <ChatFeed
          key={chatId}
          chatId={chatId}
          messages={messages}
          isLoading={isLoading}
          loadingSession={loadingSession}
          reload={reload}
          onSourceLinkClick={onSourceLinkClick}
          onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
          isFreeUser={isFreeUser}
          draftTemplateName={mode.modeOptions.template_name}
        />
      </div>
      {isFreeUser && (
        <MembershipCallout
          blockIdentifier="ai-upgrade-cta.in-chat-cta"
          className="mt-4"
          recentChatsCount={recentChatsCount}
          limitExceeded={limitExceeded}
          handleMinimize={handleMinimize}
        />
      )}
      {showPersonalizationCta && (
        <PersonalizationCta
          onClick={() => {
            dismissPersonalizationCta()
            setPersonalizeActive(true)
          }}
        />
      )}
      {!limitExceeded && <UserInput autofocus={false} adjustableHeight={true} />}
    </>
  )
}

interface DraftGenerationViewProps extends ChatContentProps {
  onCopy: () => void
  onClose: () => void
  headerTitle: string
}

const DraftGenerationView = ({
  isFreeUser,
  handleMinimize,
  onGeneratedDraftCtaClick,
  onCopy,
  onClose,
  headerTitle
}: DraftGenerationViewProps) => {
  return (
    <>
      <div className="flex flex-col flex-1 h-full border-r border-rb-gray-50">
        <div className="flex justify-between">
          <div className="text-sm font-semibold text-rb-gray-300 ml-4 mb-3">
            {headerTitle}
          </div>
          <div className="flex flex-row justify-end gap-x-4">
            <button onClick={onCopy}>
              <CopyIcon className="w-[16px]" />
            </button>

            <button onClick={onClose}>
              <CloseIcon className="w-[16px]" />
            </button>
          </div>
        </div>
        <Editor />
      </div>
      <div className="flex flex-col h-full overflow-y-auto w-[400px]">
        <div className="hide-scrollbar h-full grow overflow-y-auto">
          <ChatContent
            isFreeUser={isFreeUser}
            handleMinimize={handleMinimize}
            onGeneratedDraftCtaClick={onGeneratedDraftCtaClick}
          />
        </div>
      </div>
    </>
  )
}
