import FirstSetupFlowModal from 'domains/Onboarding/FirstSetupFlowModal'

import { useGlobalModal } from 'components/GlobalModal'

interface openFirstSetupFlowModalProps {
  numSeats: number
  teamName?: string | null
  planName?: string | null
}

const useFirstSetupFlowModal = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const handleFirstSetupFlowModalClose = () => {
    closeGlobalModal()
  }

  const openFirstSetupFlowModal = ({
    numSeats,
    teamName,
    planName
  }: openFirstSetupFlowModalProps) => {
    openGlobalModal(
      <FirstSetupFlowModal
        handleClose={handleFirstSetupFlowModalClose}
        numSeats={numSeats}
        teamName={teamName}
        planName={planName}
      />,
      {
        className: 'mx-2 max-w-[824px] rounded-lg bg-white',
        header: false,
        closeOnOutsideClick: false,
        handleClose: handleFirstSetupFlowModalClose
      }
    )
  }
  return { openFirstSetupFlowModal }
}

export default useFirstSetupFlowModal
