import { useBlock } from '@dopt/react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as PDiamondIcon } from 'images/p-diamond.svg'

interface IDoptPanel {
  blockIdentifier: string
  className?: string
  completeOnClick?: boolean
  recentChatsCount?: number
  limitExceeded?: boolean
  handleMinimize?: () => void
}

const MembershipCallout = ({
  blockIdentifier,
  className,
  recentChatsCount,
  limitExceeded,
  handleMinimize
}: IDoptPanel) => {
  const { currentUser } = useCurrentUser()
  const { showFreeTrial } = useFeatureFlags()
  const [block] = useBlock<['complete']>(blockIdentifier)
  const NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN = 3
  const userMessagesLeft =
    NUM_USER_MESSAGES_AFTER_UPGRADE_CTA_SHOWN - (recentChatsCount || 0)

  const ctaHref =
    showFreeTrial && currentUser?.is.eligibleForTrial
      ? '/try-reforge'
      : '/subscribe/choose-plan'

  const onClick = () => {
    if (handleMinimize) {
      handleMinimize()
    }

    trackCtaClicked({
      cta_location: 'AI Chat - Upgrade CTA',
      cta_type: 'card',
      text: 'Get access',
      destination: ctaHref
    })
  }

  if (!limitExceeded) {
    return (
      <div
        className={twMerge(
          'flex items-center rounded-md p-4 pr-6 transition-colors hover:no-underline',
          !block.field('background-color') && 'bg-rb-blue-50',
          className
        )}
        style={{ backgroundColor: block.field('background-color') as string }}
      >
        <div className="grow">
          <p className="text-md mb-2 flex items-center font-semibold text-[#090B0A] [&>p]:m-0 [&>p]:block [&>p]:leading-[19px] [&>div]:ml-2 [&>div]:mr-1 [&>img]:h-4 [&>img]:w-4">
            Membership preview
          </p>

          <p className="mb-0 text-xs font-medium leading-[17px] text-[#757B74]">
            {userMessagesLeft}/3 messages left this week
          </p>
        </div>

        <Button
          size="x-small"
          variant="fill"
          className="h-[32px]"
          onClick={onClick}
          href={ctaHref}
        >
          Get access
        </Button>
      </div>
    )
  }

  return (
    <div
      className={twMerge(
        'flex grow flex-col items-center justify-center gap-6 rounded-xl p-4',
        !block.field('background-color') && 'bg-rb-blue-50',
        className
      )}
      style={{ backgroundColor: block.field('background-color') as string }}
    >
      <div className="flex w-full flex-col items-center gap-2">
        <div className="text-center text-xs font-normal leading-[18px] text-rb-gray-300">
          You’ve used all of your free messages
        </div>
        <div className="text-center text-xl font-semibold text-[#090B0A]">
          Get unlimited access to personalized advice
        </div>
        <div className="text-center text-sm font-normal leading-[21px] text-[#2D2F2F]">
          Unlock full access to the AI chat, guides, and more with one of our membership
          options.
        </div>
      </div>

      <div>
        <Button
          onClick={onClick}
          variant="fill"
          className="flex h-[40px] items-center gap-2 px-4"
        >
          <span className="text-white">
            {showFreeTrial && currentUser?.is.eligibleForTrial
              ? 'Start 14-day free trial'
              : 'Become a member'}
          </span>
          <PDiamondIcon fill={'white'} className="h-4 w-4" />
        </Button>
      </div>
    </div>
  )
}

export default MembershipCallout
