import { EditorContent, PureEditorContent } from '@tiptap/react'
import React, { useMemo, useRef } from 'react'

import { LinkMenu } from '../menus'

import { useBlockEditor } from '../../hooks/useBlockEditor'

import '../styles/index.css'

import { Loader } from '../ui/Loader'
import { EditorContext } from '../../context/EditorContext'
import ImageBlockMenu from '../../extensions/ImageBlock/components/ImageBlockMenu'
import { ColumnsMenu } from '../../extensions/MultiColumn/menus'
import { TableColumnMenu, TableRowMenu } from '../../extensions/Table/menus'
import { useAIState } from '../../hooks/useAIState'
import { createPortal } from 'react-dom'
import { EditorHeader } from './components/EditorHeader'
import { TextMenu } from '../menus/TextMenu'
import { ContentItemMenu } from '../menus/ContentItemMenu'
import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

// export const BlockEditor = ({ aiToken, ydoc }: TiptapProps) => {
export const BlockEditor = () => {
  const aiState = useAIState()
  const menuContainerRef = useRef(null)
  const editorRef = useRef<PureEditorContent | null>(null)

  const { editor } = useGlobalChat()

  const providerValue = useMemo(() => {
    return {
      isAiLoading: aiState.isAiLoading,
      aiError: aiState.aiError,
      setIsAiLoading: aiState.setIsAiLoading,
      setAiError: aiState.setAiError
    }
  }, [aiState])

  if (!editor) {
    return null
  }

  const aiLoaderPortal = createPortal(
    <Loader label="AI is now doing its job." />,
    document.body
  )

  return (
    <EditorContext.Provider value={providerValue}>
      <div className="flex h-full" ref={menuContainerRef}>
        <div className="relative flex flex-col flex-1 h-full overflow-hidden">
          {/* <EditorHeader isSidebarOpen={false} toggleSidebar={() => {}} /> */}
          <EditorContent
            editor={editor}
            ref={editorRef as React.RefObject<HTMLDivElement>}
            className="flex-1 overflow-y-auto"
          />
          {/* <ContentItemMenu editor={editor} /> */}
          <LinkMenu editor={editor} appendTo={menuContainerRef} />
          <TextMenu editor={editor} />
          <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
          <TableRowMenu editor={editor} appendTo={menuContainerRef} />
          <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
          <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        </div>
      </div>
      {aiState.isAiLoading && aiLoaderPortal}
    </EditorContext.Provider>
  )
}

export default BlockEditor
