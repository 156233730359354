import { useState } from 'react'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { ModalContent } from 'components/Modal'
import { ArrowRight } from 'components/icons'

import { useSubmitOnboardingQuestionnaireMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import notifyError from 'utils/errorNotifier'
import { trackOnboardingStepCompleted } from 'utils/tracking/analytics'

import BulkEmailStep from './Steps/BulkEmailStep'
import TeamPassAssignStep from './Steps/TeamPassAssignTypeStep'
import TeamSetupStep from './Steps/TeamSetupStep'
import {
  BULK_EMAIL_STEP,
  STEP_NAMES,
  TEAM_ASSIGN_PASS_STEP,
  TEAM_SETUP_STEP
} from './Steps/constants'
import { cleanAndSplitEmails } from './helpers'

interface FirstSetupFlowModalProps {
  handleClose: () => void
  numSeats: number
  teamName?: string | null
  planName?: string | null
}

const FirstSetupFlowModal = ({
  handleClose,
  numSeats,
  teamName,
  planName
}: FirstSetupFlowModalProps) => {
  const { currentUser } = useCurrentUser()
  const initialStep = 1
  const [currentStep, setCurrentStep] = useState(initialStep)
  const [answers, setAnswers] = useState({ teamName: teamName } as { [key: string]: any })
  const [
    submitOnboardingQuestionnaire,
    { error: submitOnboardingQuestionnaireError, loading: submitting }
  ] = useSubmitOnboardingQuestionnaireMutation({
    onError: (e) => notifyError(e),
    update: (cache, { data }) => {
      if (!currentUser) return
      cache.modify({
        id: cache.identify(currentUser),
        fields: {
          homepageChecks() {
            return data?.submitOnboardingQuestionnaire?.homepageChecks
          }
        }
      })
    }
  })
  const { autoAssignCoursePass } = useFeatureFlags()

  // Adds support for hiding steps based on feature flags
  let stepCount = 3

  if (!autoAssignCoursePass) {
    stepCount = 2
  }

  const currentStepDisplay = () => {
    if (!autoAssignCoursePass) {
      if (currentStep > 1) {
        return currentStep - 1
      }
    }
    return currentStep
  }

  const handleSetAnswer = (key: string, value: string) => {
    setAnswers({ ...answers, [key]: value })
  }

  const submitAnswers = async () => {
    const emailsToInvite = answers.emails || ''
    const businessModelTags = answers.businessModel || []
    const audienceTags = answers.audience || []
    const usage = answers.usage || ''
    const manualAssignPasses =
      answers.manualAssignPasses?.toLowerCase() === 'true' || false
    const { data } = await submitOnboardingQuestionnaire({
      variables: {
        input: {
          emailsToInvite: cleanAndSplitEmails(emailsToInvite),
          businessModel: businessModelTags.sort().join(','),
          companyAudience: audienceTags.sort().join(','),
          usage: usage,
          teamName: answers.teamName,
          manualAssignPasses: manualAssignPasses
        }
      }
    })
    if (data?.submitOnboardingQuestionnaire?.questionnaire) {
      handleClose()
    }
  }

  const handleNext = () => {
    trackOnboardingStepCompleted({
      onboarding_type: 'subscription_owner',
      plan_name: planName || '',
      step_name: STEP_NAMES[currentStep],
      step_number: currentStep,
      option_1_name: currentStep === TEAM_SETUP_STEP ? 'business_model' : undefined,
      option_1_value: currentStep === TEAM_SETUP_STEP ? answers.businessModel : undefined,
      option_2_name: currentStep === TEAM_SETUP_STEP ? 'company_audience' : undefined,
      option_2_value: currentStep === TEAM_SETUP_STEP ? answers.audience : undefined
    })
    if (currentStep === BULK_EMAIL_STEP) {
      submitAnswers()
    } else {
      if (!autoAssignCoursePass && currentStep === TEAM_SETUP_STEP) {
        setCurrentStep(currentStep + 2)
      } else {
        setCurrentStep(currentStep + 1)
      }
    }
  }

  const handleBack = () => {
    if (!autoAssignCoursePass && currentStep === BULK_EMAIL_STEP) {
      setCurrentStep(currentStep - 2)
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const isDisabled = () => {
    if (currentStep === TEAM_SETUP_STEP) {
      return (
        !answers.teamName?.length ||
        !answers.businessModel?.length ||
        !answers.audience?.length
      )
    }
    if (currentStep === BULK_EMAIL_STEP) {
      return !answers.emails?.length
    }
    return false
  }

  if (submitting) {
    return <Loading className="my-10" />
  }

  if (submitOnboardingQuestionnaireError) {
    return <ErrorMessage error={submitOnboardingQuestionnaireError} />
  }

  return (
    <ModalContent
      dataTest="onboarding-modal"
      className="flex flex-col gap-8 rounded-xl bg-onboarding bg-no-repeat p-16 pb-10"
    >
      {currentStep === TEAM_SETUP_STEP && (
        <TeamSetupStep answers={answers} handleSetAnswer={handleSetAnswer} />
      )}
      {currentStep === TEAM_ASSIGN_PASS_STEP && (
        <TeamPassAssignStep
          answer={answers.manualAssignPasses || false}
          handleSetAnswer={handleSetAnswer}
        />
      )}
      {currentStep === BULK_EMAIL_STEP && (
        <BulkEmailStep
          answer={answers.emails || ''}
          handleSetAnswer={handleSetAnswer}
          skip={handleNext}
          numSeats={numSeats}
        />
      )}
      <div className="flex items-center justify-between">
        <div className="text-xs font-medium uppercase text-[#888]">
          Step {currentStepDisplay()}/{stepCount}
        </div>
        <div className="flex">
          {initialStep < currentStep && (
            <Button
              variant="text-only"
              className="sm:mr-4"
              onClick={handleBack}
              iconBefore={
                <div className="rotate-180 text-black">
                  <ArrowRight />
                </div>
              }
            >
              Back
            </Button>
          )}
          <Button disabled={isDisabled()} iconAfter={<ArrowRight />} onClick={handleNext}>
            {currentStep === BULK_EMAIL_STEP ? 'Continue' : 'Next'}
          </Button>
        </div>
      </div>
    </ModalContent>
  )
}

export default FirstSetupFlowModal
