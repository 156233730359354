import Button from 'components/Button'
import RfHeader1Alt from 'components/typography/RfHeader/RfHeader1Alt'

import { AUDIENCE_OPTIONS, BUSINESS_MODEL_OPTIONS } from './constants'

interface TeamSetupStepProps {
  answers: { [key: string]: any }
  handleSetAnswer: (key: string, value: any) => void
}

interface TagQuestionProps {
  option: string
  handleChange: (value: string) => void
  answers: string[]
}

const TagQuestion = ({ option, handleChange, answers }: TagQuestionProps) => {
  const selected = answers.includes(option)
  return (
    <Button
      size="x-small"
      variant={selected ? 'fill' : 'outline'}
      onClick={() => handleChange(option)}
      className="text-xs font-semibold leading-4"
      shape="rounded-full"
    >
      {option}
    </Button>
  )
}

const TeamSetupStep = ({ answers, handleSetAnswer }: TeamSetupStepProps) => {
  const handleSelectChange = (key: string, value: string) => {
    let newVal
    if (!answers[key] || !answers[key].includes(value)) {
      newVal = [...(answers[key] || []), value]
    } else {
      newVal = answers[key].filter((v: string) => v !== value)
    }
    handleSetAnswer(key, newVal)
  }

  return (
    <>
      <RfHeader1Alt>Set up your team plan</RfHeader1Alt>
      <div className="flex flex-col gap-1">
        <label htmlFor="teamNameInput" className="font-semibold leading-6 text-rb-black">
          Team Name &#42;
        </label>
        <input
          type="text"
          id="teamNameInput"
          data-testid="team-setup-team-name"
          required
          onChange={(e) => handleSetAnswer('teamName', e.target.value)}
          defaultValue={answers.teamName}
          placeholder="Enter Team Name"
          className="max-w-[450px] rounded border border-gray-400 bg-white py-2 px-4 leading-6 text-rb-black placeholder:text-gray-400"
        />
      </div>
      <div className="flex w-full flex-col gap-4">
        <label htmlFor="businessModel" className="font-semibold leading-6 text-rb-black">
          Which best describes your business model? Select all that apply &#42;
        </label>
        <div className="flex flex-wrap gap-4" data-testid="team-setup-business-model">
          {BUSINESS_MODEL_OPTIONS.map((option) => (
            <TagQuestion
              key={option}
              option={option}
              handleChange={(value) => handleSelectChange('businessModel', value)}
              answers={answers.businessModel || []}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <label htmlFor="audience" className="font-semibold leading-6 text-rb-black">
          Which best described your audience? Select all that apply &#42;
        </label>
        <div className="flex flex-wrap gap-4" data-testid="team-setup-audience">
          {AUDIENCE_OPTIONS.map((option) => (
            <TagQuestion
              key={option}
              option={option}
              handleChange={(value) => handleSelectChange('audience', value)}
              answers={answers.audience || []}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default TeamSetupStep
