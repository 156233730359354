import { datadogRum } from '@datadog/browser-rum'
import type { Editor } from '@tiptap/react'
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { v4 as uuid } from 'uuid'

import { useBlockEditor } from 'domains/Ai/Chat/Editor/hooks/useBlockEditor'

import {
  AiPersonalizationQuery,
  DedupedTopicsAndFunctionsQuery,
  useAiExchangeChatIdForExtIdQuery,
  useAiPersonalizationQuery,
  useAiRecentChatsCountQuery,
  useAiSessionHistoryQuery,
  useDedupedTopicsAndFunctionsQuery,
  useSuggestedPromptsQuery
} from 'gql'

import { useChat } from 'hooks/ai/useChat'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { trackAiChatDraftClosed } from 'utils/tracking/analytics'

import {
  SELECT_AUDIENCE_PLACEHOLDER,
  SELECT_INDUSTRY_PLACEHOLDER
} from './Chat/PersonalizeContent'
import { getHeaders, getSuggestedQuestionsFromMessage } from './Chat/helpers'
import { useGlobalChatTracking } from './GlobalChatTrackingProvider'
import { htmlToMarkdown } from './helpers'
import { RecentChatsContextProvider } from './hooks/useRecentChats'
import { Data, Message, MessageOptions, OpenAndSendMessageInput } from './types'

const defaultMessageOptions: MessageOptions = {
  isSuggestedFollowup: false,
  mode: 'default'
}

const DRAFT_CONTENT_REGEX = /:::draft(?:\{[^}]*\})?\s*([\s\S]*?):::/
const DRAFT_TITLE_REGEX = /:::draft\{.*?title="([^"]*)".*?\}/

export type Context = {
  chatId: string
  isChatOpen: boolean
  setIsChatOpen: (isOpen: boolean) => void
  setChatId: (id: string) => void
  messages: Message[]
  isLoading: boolean
  activeSession: boolean
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  minimize: () => void
  toggle: ({ ctaText }: { ctaText?: string }) => void
  endSession: () => void
  loadSession: (chatId: string) => void
  loadingSession: boolean
  sendMessage: (message: string, options?: Partial<MessageOptions>) => void
  stopGeneration: () => void
  reload: () => void
  openChatWithMessages: (messages: Message[], ctaText: string) => void
  openChatAndSendMessage: (
    message: string,
    pageLocation: string,
    options?: Partial<MessageOptions>
  ) => void
  mode: {
    mode: string
    modeOptions: Record<string, any>
  }
  setMode: React.Dispatch<
    React.SetStateAction<{
      mode: string
      modeOptions: Record<string, any>
    }>
  >
  resetMode: () => void
  recentChatsCount: number
  showPersonalizationCta: boolean
  dismissPersonalizationCta: () => void
  editor: Editor | null
  populateEditor: ({
    message,
    title,
    htmlString
  }: {
    message: Message
    title: string
    htmlString: string
  }) => void
  editorHeaderTitle: string
  setEditorHeaderTitle: (title: string) => void
  showEditDraftView: boolean
  setShowEditDraftView: (show: boolean) => void
  personalizeActive: boolean
  setPersonalizeActive: (active: boolean) => void
  suggestedPrompts: string[]
  suggestedPromptsLoading: boolean
  onChatResponseFinish: (message: Message) => void
  dedupedTopicsAndFunctions?: DedupedTopicsAndFunctionsQuery
  dedupedTopicsAndFunctionsLoading: boolean
  personalizationData?: AiPersonalizationQuery
  personalizationLoading: boolean
  menuSideBarIsOpen: boolean
  setMenuSideBarIsOpen: (isOpen: boolean) => void
  messageDraftBeingEdited: Message | null
  setMessageDraftBeingEdited: (message: Message | null) => void
  clearAndCloseEditDraftView: () => void
}

export const GlobalChatContext = createContext<Context | null>(null)

const MAX_RETRY = 3

export const GlobalChatProvider = ({
  isLoggedIn,
  children
}: {
  isLoggedIn: boolean
  children: ReactNode
}) => {
  const { aiBeta } = useFeatureFlags()
  const { pathname } = useLocation()
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [personalizeActive, setPersonalizeActive] = useState(false)
  const [chatId, setChatId] = useState<string>(getOrCreateChatId())
  const [menuSideBarIsOpen, setMenuSideBarIsOpen] = useState(false)

  const { currentUser } = useCurrentUser()

  const { data: personalizationData, loading: personalizationLoading } =
    useAiPersonalizationQuery({
      skip: !isLoggedIn || !isChatOpen
    })

  const {
    data,
    refetch,
    loading: loadingSession
  } = useAiSessionHistoryQuery({
    variables: { sessionId: chatId },
    skip: !isLoggedIn || !aiBeta || !isChatOpen
  })

  const { data: countData, client } = useAiRecentChatsCountQuery({
    skip: !isLoggedIn || !isChatOpen
  })
  const recentChatsCount = countData?.recentChatsCount || 0

  const {
    trackChatOpened,
    trackChatClosed,
    trackChatSuggestionClicked,
    trackChatAutoRetry,
    trackChatExpanded,
    trackChatDraftOpened,
    trackChatDraftCreated
  } = useGlobalChatTracking()

  const requestStarted = useRef(false)
  const retryCount = useRef(0)

  const { editor } = useBlockEditor()
  const [showEditDraftView, setShowEditDraftView] = useState(false)
  // NOTE: this is primarily used for tracking.
  // we could probably just use this to remove the redundancy with showEditDraftView
  const [messageDraftBeingEdited, setMessageDraftBeingEdited] = useState<Message | null>(
    null
  )
  const [editorHeaderTitle, setEditorHeaderTitle] = useState('')

  const clearAndCloseEditDraftView = useCallback(() => {
    setShowEditDraftView(false)
    setMessageDraftBeingEdited(null)
  }, [])

  const hasCompleteDraftContent = useCallback((text: string) => {
    const match = text.match(DRAFT_CONTENT_REGEX)
    return match !== null && match[1].trim() !== ''
  }, [])

  const appendRef = useRef<(message: Message, options?: any) => void>()
  const appendDelayedMessageTimerRef = useRef<number | null>(null)
  const onChatResponseFinishRef = useRef<(message: Message) => void>()

  const {
    messages,
    setMessages,
    isLoading,
    reload: reloadLastMessage,
    stop,
    error,
    append
  } = useChat<Data>({
    // On page refresh, we need to restore the chat session
    id: chatId,
    // also pass message id to backend
    sendExtraMessageFields: true,
    headers: getHeaders(),
    body: {
      retry_count: retryCount.current,
      pathname
    },
    onFinish: (message) => onChatResponseFinishRef.current?.(message)
  })

  const [mode, setMode] = useState<{
    mode: 'default' | 'document_generation'
    modeOptions: Record<string, any>
  }>({ mode: 'default', modeOptions: {} })

  const resetMode = useCallback(() => {
    setMode({ mode: 'default', modeOptions: {} })
  }, [])

  // we always want to send same message options on message reload
  const reload = useCallback(() => {
    reloadLastMessage({
      options: {
        body: {
          pathname,
          ...defaultMessageOptions,
          mode: mode.mode,
          modeOptions: mode.modeOptions
        }
      }
    })
  }, [reloadLastMessage, pathname, mode.mode, mode.modeOptions])

  // Update appendRef whenever append changes
  useEffect(() => {
    appendRef.current = append
  }, [append])

  const populateEditor = useCallback(
    ({
      message,
      title,
      htmlString
    }: {
      message: Message
      title: string
      htmlString: string
    }) => {
      setEditorHeaderTitle(title)
      setIsExpanded(true)
      setMessageDraftBeingEdited(message)
      trackChatExpanded({
        chatId,
        mode: mode.mode ?? 'default'
      })
      trackChatDraftOpened({
        chatId,
        // NOTE: we probably want to update naming to reduce confusion
        // template_name in mode options is actually the LD prompt key
        templateName: message.modeOptions?.label
      })
      setShowEditDraftView(true)
      setMenuSideBarIsOpen(false)
      editor?.commands.setContent(htmlString)
    },
    [
      setMenuSideBarIsOpen,
      chatId,
      editor?.commands,
      trackChatExpanded,
      trackChatDraftOpened,
      mode.mode
    ]
  )

  const onChatResponseFinish = useCallback(
    async (message: Message) => {
      if (hasCompleteDraftContent(message.content)) {
        const text = message.content?.match(DRAFT_CONTENT_REGEX)?.[1] ?? ''
        const extractedTitle = message.content?.match(DRAFT_TITLE_REGEX)?.[1] ?? ''
        const htmlString = await htmlToMarkdown(text)

        // NOTE: relying on the fact that objects get passed by reference in js
        // so this will update the message object in the cache
        // this is to make this message in memory, act like one that was fetched from DB, which would have this property
        // this property is used to show draft in editor
        message.documents = {
          draft: {
            content: text
          }
        }
        // NOTE: similar to above, updating message object in memory to act like one fetched from DB
        message.mode = mode.mode
        message.modeOptions = mode.modeOptions

        // todo: probably add modeOptions here
        populateEditor({ message, title: extractedTitle, htmlString })

        trackChatDraftCreated({
          chatId,
          templateName: message.modeOptions?.label
        })

        // Append a new message using the current append function
        // Schedule the delayed message
        appendDelayedMessageTimerRef.current = window.setTimeout(() => {
          appendRef.current?.({
            id: uuid(),
            role: 'assistant',
            content:
              'Would you like any help with making changes or adding more details to this draft?',
            isPredefined: true
          })
        }, 2000) // 2 second delay
      }
    },
    [
      hasCompleteDraftContent,
      populateEditor,
      chatId,
      mode.modeOptions,
      mode.mode,
      trackChatDraftCreated
    ]
  )

  // Update onChatResponseFinishRef whenever onChatResponseFinish changes
  // and clear timeout
  useEffect(() => {
    onChatResponseFinishRef.current = onChatResponseFinish
    return () => {
      if (appendDelayedMessageTimerRef.current) {
        clearTimeout(appendDelayedMessageTimerRef.current)
      }
    }
  }, [onChatResponseFinish])

  const lastMessageIsAssistant = messages[messages.length - 1]?.role === 'assistant'

  useEffect(() => {
    if (error) {
      datadogRum.addError(error.message, {
        chatId
      })
    }
  }, [error, chatId])

  useEffect(() => {
    if (isLoading) {
      requestStarted.current = true
    }
    if (requestStarted.current && !isLoading && !lastMessageIsAssistant) {
      if (retryCount.current < MAX_RETRY) {
        datadogRum.addAction('chat-auto-retry', {
          chatId,
          retryCount: retryCount.current
        })
        trackChatAutoRetry({
          chatId: chatId,
          retryCount: retryCount.current
        })
        retryCount.current++
        reload()
        return
      }
    }
    if (!isLoading) {
      requestStarted.current = false
    }
  }, [
    isLoading,
    lastMessageIsAssistant,
    reload,
    chatId,
    currentUser?.accessPolicyKind,
    trackChatAutoRetry
  ])

  useEffect(() => {
    if (
      messages.length === 0 &&
      data?.aiSessionHistory &&
      data.aiSessionHistory.length > 0
    ) {
      setMessages(data.aiSessionHistory as Message[])
    }
  }, [data?.aiSessionHistory, messages, setMessages])

  // NOTE: Assuming the last message is the one with sources and suggestions etc
  const mostRecentMessage = messages[messages.length - 1]

  const suggestedPromptsFromMessage = useMemo(() => {
    return getSuggestedQuestionsFromMessage(mostRecentMessage) || []
  }, [mostRecentMessage])

  const {
    data: suggestedPromptsData,
    loading: suggestedPromptsLoading,
    refetch: suggestedPromptsRefetch
  } = useSuggestedPromptsQuery({
    variables: {
      path: pathname
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip:
      !isChatOpen ||
      !isLoggedIn ||
      (Array.isArray(suggestedPromptsFromMessage) &&
        suggestedPromptsFromMessage.length > 0)
  })

  const { data: dedupedTopicsAndFunctions, loading: dedupedTopicsAndFunctionsLoading } =
    useDedupedTopicsAndFunctionsQuery({
      skip: !isChatOpen || !isLoggedIn
    })

  useEffect(() => {
    if (isChatOpen) {
      suggestedPromptsRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isChatOpen])

  const suggestedPrompts = useMemo(() => {
    // NOTE this relies on skipping the query if there are already prompts in the message
    // so we can have the message prompts as a fallback
    return suggestedPromptsData?.suggestedPrompts || suggestedPromptsFromMessage || []
  }, [suggestedPromptsData?.suggestedPrompts, suggestedPromptsFromMessage])

  const activeSession = messages.length > 0

  const stopGeneration = useCallback(() => {
    stop()
  }, [stop])

  const endSession = useCallback(() => {
    resetMode()
    stopGeneration()
    const newChatId = uuid()

    setChatId(newChatId)
    localStorage.setItem('chatId', newChatId)

    trackChatClosed({
      chatId: chatId,
      mode: mode.mode ?? 'default'
    })
    if (mode.mode === 'document_generation') {
      trackAiChatDraftClosed({
        chat_session_id: chatId,
        draft_type: mode.modeOptions.label
      })
    }

    trackChatOpened({
      chatId: newChatId,
      isSuggestedPrompt: false,
      isDraft: false,
      chatSessionType: 'new',
      mode: mode.mode ?? 'default'
    })
  }, [
    setChatId,
    chatId,
    stopGeneration,
    trackChatOpened,
    trackChatClosed,
    mode,
    resetMode
  ])

  const loadSession = useCallback(
    (chatIdToLoad: string) => {
      if (loadingSession) return

      if (chatIdToLoad === chatId) return

      resetMode()

      // If a response is currently streaming, stop the generation
      stopGeneration()

      setChatId(chatIdToLoad)
      localStorage.setItem('chatId', chatIdToLoad)

      trackChatClosed({
        chatId: chatId,
        mode: mode.mode ?? 'default'
      })
      if (mode.mode === 'document_generation') {
        trackAiChatDraftClosed({
          chat_session_id: chatId,
          draft_type: mode.modeOptions.template_name
        })
      }

      trackChatOpened({
        chatId: chatIdToLoad,
        isSuggestedPrompt: false,
        isDraft: false,
        chatSessionType: 'existing',
        mode: mode.mode ?? 'default'
      })

      refetch({ sessionId: chatIdToLoad })
    },
    [
      loadingSession,
      refetch,
      chatId,
      stopGeneration,
      trackChatOpened,
      trackChatClosed,
      mode,
      resetMode
    ]
  )

  const sendMessage = useCallback<Context['sendMessage']>(
    (message, options = {}) => {
      if (options.isSuggestedFollowup) {
        trackChatSuggestionClicked({
          chatId: chatId
        })
      }

      client.cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          recentChatsCount: (existingRecentChatsCount = 0) => {
            return existingRecentChatsCount + 1
          }
        }
      })

      retryCount.current = 0

      append(
        {
          role: 'user',
          content: message,
          mode: mode.mode
        },
        {
          options: {
            body: {
              pathname,
              ...defaultMessageOptions,
              mode: mode.mode,
              modeOptions: mode.modeOptions,
              ...options
            }
          }
        }
      )
    },
    [append, pathname, chatId, mode, client.cache, trackChatSuggestionClicked]
  )

  const [initialMessagesToSetOnOpen, setInitialMessagesToSetOnOpen] = useState<Message[]>(
    []
  )
  useEffect(() => {
    if (initialMessagesToSetOnOpen.length === 0 || !chatId) return

    setMessages(initialMessagesToSetOnOpen)

    setIsChatOpen(true)
    setInitialMessagesToSetOnOpen([])
  }, [initialMessagesToSetOnOpen, chatId, setMessages])
  const openChatWithMessages = useCallback<Context['openChatWithMessages']>(
    (messages, ctaText) => {
      const isDraft = mode.mode === 'document_generation'
      setChatId(uuid())
      setInitialMessagesToSetOnOpen(messages)
      trackChatOpened({
        chatId,
        ctaText,
        chatSessionType: 'new',
        mode: mode.mode ?? 'default',
        isDraft: isDraft,
        draftType: isDraft ? mode.modeOptions.template_name : null
      }) // assuming new chat since currently only being used in search, and new uuid set everytime
    },
    [trackChatOpened, chatId, mode]
  )

  const [initialMessageToSendOnOpen, setInitialMessageToSendOnOpen] =
    useState<OpenAndSendMessageInput | null>(null)
  useEffect(() => {
    if (!initialMessageToSendOnOpen || !chatId) return

    sendMessage(initialMessageToSendOnOpen.message, initialMessageToSendOnOpen.options)
    setIsChatOpen(true)
    setInitialMessageToSendOnOpen(null)
  }, [initialMessageToSendOnOpen, chatId, sendMessage])
  const openChatAndSendMessage = useCallback<Context['openChatAndSendMessage']>(
    (message, pageLocation, options) => {
      const isDraft = mode.mode === 'document_generation'
      const newChatId = uuid()
      setChatId(newChatId)
      setInitialMessageToSendOnOpen({ message: message, options: options })
      trackChatOpened({
        chatId: newChatId,
        ctaText: message,
        pageLocation: pageLocation,
        isSuggestedPrompt: !isDraft, // TODO: will eventually need to be more explicit
        isDraft: isDraft,
        draftType: isDraft ? mode.modeOptions.template_name : null,
        chatSessionType: 'new',
        mode: mode.mode ?? 'default'
      })
    },
    [trackChatOpened, setChatId, mode]
  )

  const minimize = useCallback(() => {
    setIsChatOpen(false)
    trackChatClosed({
      chatId: chatId,
      mode: mode.mode ?? 'default'
    })
    if (mode.mode === 'document_generation') {
      trackAiChatDraftClosed({
        chat_session_id: chatId,
        draft_type: mode.modeOptions.template_name
      })
    }
  }, [chatId, trackChatClosed, mode])

  const toggle = useCallback(
    ({ ctaText }: { ctaText?: string }) => {
      if (!isChatOpen) {
        trackChatOpened({
          chatId,
          ctaText,
          chatSessionType: messages.length > 0 ? 'existing' : 'new',
          mode: mode.mode ?? 'default'
        }) // assuming new because currently only being used for dopt tour
      }

      setIsChatOpen((isOpen) => !isOpen)
    },
    [isChatOpen, trackChatOpened, chatId, mode, messages.length]
  )

  // handle query params
  const [searchParams, setSearchParams] = useSearchParams()
  const chatIdParam = useMemo(() => searchParams.get('chatId'), [searchParams])
  const aiChatQuestionParam = useMemo(
    () => searchParams.get('aiChatQuestion'),
    [searchParams]
  )
  const { data: attemptedIdExchange } = useAiExchangeChatIdForExtIdQuery({
    variables: { chatId: chatIdParam || '' },
    skip: !chatIdParam
  })
  useEffect(() => {
    const successfulIdExchange = attemptedIdExchange?.exchangeChatIdForExtId
    if (successfulIdExchange) {
      setChatId(getOrCreateChatId(successfulIdExchange))
      setIsChatOpen(true)
    } else if (aiChatQuestionParam) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete('aiChatQuestion')
      setSearchParams(newSearchParams, { replace: true })
      openChatAndSendMessage(aiChatQuestionParam, 'url_params')
    }
  }, [
    attemptedIdExchange,
    aiChatQuestionParam,
    searchParams,
    openChatAndSendMessage,
    setSearchParams
  ])

  // Add a state so that Personalization cta dismissal triggers a rerender
  const [personalizationCtaDismissed, setPersonalizationCtaDismissed] = useState(false)

  const showPersonalizationCta: boolean = useMemo(() => {
    if (mode.mode === 'document_generation') return false

    const personalization = personalizationData?.aiPersonalization

    if (!personalization || personalizationLoading) {
      return false
    }

    const personalizationKeys = Object.keys(personalization).filter(
      // enabled is now true by default, so we don't consider it as a filled value
      (k) => !['__typename', 'id', 'enabled'].includes(k)
    )
    const filledPersonalizationValues = personalizationKeys
      .map((k: keyof typeof personalization) => personalization[k])
      .filter(
        (v) =>
          // we want to catch the odd (historical) case where the user selected the placeholder option values
          // this should not happen moving forward as they now get stored as empty string
          !!v && v !== SELECT_AUDIENCE_PLACEHOLDER && v !== SELECT_INDUSTRY_PLACEHOLDER
      )
    const personalizationSufficient =
      personalization.enabled && filledPersonalizationValues.length >= 2

    if (personalizationSufficient) {
      return false
    }

    const lastSeen = localStorage.getItem('personalization_cta_last_seen')
    const ctaClicked = localStorage.getItem('personalization_cta_clicked')
    const now = Date.now()
    const oneWeek = 7 * 24 * 60 * 60 * 1000
    const firstMessageReceivedFromAssistant =
      messages.filter((m) => m.role === 'assistant').length >= 1
    const weekPassedSinceCtaLastSeen = lastSeen && now - parseInt(lastSeen) > oneWeek

    if (!ctaClicked && firstMessageReceivedFromAssistant) {
      localStorage.setItem('personalization_cta_last_seen', now.toString())
      localStorage.removeItem('personalization_cta_clicked')
      return true
    }

    if (lastSeen && ctaClicked && weekPassedSinceCtaLastSeen) {
      localStorage.removeItem('personalization_cta_last_seen')
      localStorage.removeItem('personalization_cta_clicked')
      setPersonalizationCtaDismissed(false)
      return false
    }

    return false
    // NOTE: intentionally disabling eslint unececessary deps warning
    // because we want this to be recomputed when personalization is dismissed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personalizationData,
    personalizationLoading,
    messages,
    personalizationCtaDismissed,
    mode.mode
  ])

  const dismissPersonalizationCta = useCallback(() => {
    localStorage.setItem('personalization_cta_clicked', 'true')
    setPersonalizationCtaDismissed(true)
  }, [])

  const currentPrePersistedChat = useMemo(() => {
    if (!chatId || messages.length < 1) return

    const firstUserSentChatMessage = messages.find((message) => message.role === 'user')
    if (!firstUserSentChatMessage) return

    return {
      extId: chatId,
      title: firstUserSentChatMessage.content,
      createdAt: new Date()
    }
    // messages update frequently when using chat. We only want to re-evaluate
    // when a new message is pushed to the list or when the current chatId changes
    // eslint-disable-next-line
  }, [chatId, messages.length])

  const value = useMemo<Context>(
    () => ({
      chatId,
      isChatOpen,
      setIsChatOpen,
      setChatId,
      menuSideBarIsOpen,
      setMenuSideBarIsOpen,
      messages,
      isLoading,
      activeSession,
      isExpanded,
      setIsExpanded,
      minimize,
      toggle,
      endSession,
      loadSession,
      loadingSession,
      sendMessage,
      stopGeneration,
      reload,
      openChatWithMessages,
      openChatAndSendMessage,
      mode,
      setMode,
      resetMode,
      recentChatsCount,
      showPersonalizationCta,
      dismissPersonalizationCta,
      editor,
      populateEditor,
      editorHeaderTitle,
      setEditorHeaderTitle,
      showEditDraftView,
      setShowEditDraftView,
      personalizeActive,
      setPersonalizeActive,
      suggestedPrompts,
      suggestedPromptsLoading,
      onChatResponseFinish,
      dedupedTopicsAndFunctions,
      dedupedTopicsAndFunctionsLoading,
      personalizationData,
      personalizationLoading,
      messageDraftBeingEdited,
      setMessageDraftBeingEdited,
      clearAndCloseEditDraftView
    }),
    [
      chatId,
      isChatOpen,
      setIsChatOpen,
      setChatId,
      menuSideBarIsOpen,
      setMenuSideBarIsOpen,
      messages,
      isLoading,
      activeSession,
      isExpanded,
      setIsExpanded,
      minimize,
      toggle,
      endSession,
      loadSession,
      loadingSession,
      sendMessage,
      stopGeneration,
      reload,
      openChatWithMessages,
      openChatAndSendMessage,
      mode,
      setMode,
      resetMode,
      recentChatsCount,
      showPersonalizationCta,
      dismissPersonalizationCta,
      editor,
      populateEditor,
      editorHeaderTitle,
      setEditorHeaderTitle,
      showEditDraftView,
      setShowEditDraftView,
      personalizeActive,
      setPersonalizeActive,
      suggestedPrompts,
      suggestedPromptsLoading,
      onChatResponseFinish,
      dedupedTopicsAndFunctions,
      dedupedTopicsAndFunctionsLoading,
      personalizationData,
      personalizationLoading,
      messageDraftBeingEdited,
      setMessageDraftBeingEdited,
      clearAndCloseEditDraftView
    ]
  )

  return (
    <RecentChatsContextProvider
      userId={currentUser?.id}
      isChatOpen={isChatOpen}
      currentChat={currentPrePersistedChat}
    >
      <GlobalChatContext.Provider value={value}>{children}</GlobalChatContext.Provider>
    </RecentChatsContextProvider>
  )
}

export const useGlobalChat = () => {
  const context = useContext(GlobalChatContext)
  if (!context) {
    throw new Error('useGlobalChat must be used within a GlobalChatProvider')
  }
  return context
}

const getOrCreateChatId = (chatIdParam?: string | null) => {
  let chatId = chatIdParam || localStorage.getItem('chatId')
  if (!chatId) {
    chatId = uuid()
    localStorage.setItem('chatId', chatId)
  }
  return chatId
}
