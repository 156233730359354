import { DoptProvider } from '@dopt/react'
import { ReactNode } from 'react'

import { useCurrentUser } from 'hooks/useCurrentUser'

interface DoptProviderWrapperProps {
  children: ReactNode
}

const apiKey = process.env.DOPT_BLOCK_API_KEY || ''

const DoptProviderWrapper = ({ children }: DoptProviderWrapperProps) => {
  const { currentUser } = useCurrentUser()

  return (
    <DoptProvider
      userId={currentUser?.id || undefined}
      flowVersions={{
        'ai-chat-product-tour': 'latest',
        'ai-chat-product-tour-p2': 'latest',
        'ai-chat-product-tour-p3': 'latest',
        'ai-upgrade-cta': 'latest',
        'announcement-banner-1': 'latest',
        'announcement-banner-2': 'latest',
        'announcement-banner-3': 'latest',
        'artifacts-index-featured-subtypes-banner': 'latest',
        'copilot-cta-panel': 'latest',
        'slack-announcement-banner': 'latest',
        'extension-launch-modal': 'latest'
      }}
      apiKey={apiKey}
    >
      {children}
    </DoptProvider>
  )
}

export default DoptProviderWrapper
