import { ApolloProvider } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ReferrerProvider, useReferrer } from 'ReferrerProvider'
import { privateApolloClient, publicApolloClient } from 'apolloClient'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { GlobalChat } from 'domains/Ai/Chat'
import { GlobalChatProvider } from 'domains/Ai/GlobalChatProvider'
import { GlobalChatTrackingProvider } from 'domains/Ai/GlobalChatTrackingProvider'
import { AddPostModalProvider } from 'domains/Post/AddPostModal'

import { Loading } from 'components'
import BugsnagProvider, { SetupBugsnagUser } from 'components/BugsnagProvider'
import DatadogProvider from 'components/DatadogProvider/DatadogProvider'
import { SetupDatadogUserSession } from 'components/DatadogProvider/SetupDatadogUserSession'
import { GlobalModalProvider } from 'components/GlobalModal'
import { SetupLaunchDarklyUser } from 'components/LaunchDarklyProvider/SetupLaunchDarklyUser'
import DoptProvider from 'components/dopt/DoptProvider'
import RouterProvider from 'components/providers/RouterProvider'

// import { SetupSprig } from 'components/SetupSprig'
import { useUrlChangeMutation } from 'gql'

import { UserContextProvider } from 'hooks/useCurrentUser'
import useFlashMessage from 'hooks/useFlashMessage'
import { LocalStorageGlobalContextProvider } from 'hooks/useLocalStorageGlobal'

import 'utils/polyfill'
import { trackPage } from 'utils/tracking/segment'

import PageLoadContext from '../javascript/domains/Sanity/marketingSite/Contexts/PageLoadContext'

export const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY as string)

interface GlobalProvidersProps {
  isLoggedIn: boolean
  children: React.ReactNode[]
}

const GlobalProviders = ({ isLoggedIn, children }: GlobalProvidersProps) => {
  return (
    <RouterProvider>
      <BugsnagProvider>
        <DatadogProvider>
          <LocalStorageGlobalContextProvider>
            <ReferrerProvider isLoggedIn={isLoggedIn}>
              <InitialSetup isLoggedIn={isLoggedIn}>
                <ApolloProvider
                  client={isLoggedIn ? privateApolloClient : publicApolloClient}
                >
                  <UserContextProvider isLoggedIn={isLoggedIn}>
                    <DoptProvider>
                      <Elements stripe={stripePromise}>
                        <SetupBugsnagUser />
                        <SetupDatadogUserSession />
                        {/* <SetupSprig isLoggedIn={isLoggedIn} /> */}
                        <SetupLaunchDarklyUser />
                        <GlobalChatTrackingProvider isLoggedIn={isLoggedIn}>
                          <GlobalChatProvider isLoggedIn={isLoggedIn}>
                            <GlobalModalProvider>
                              <AddPostModalProvider isLoggedIn={isLoggedIn}>
                                {children}
                              </AddPostModalProvider>
                              <GlobalChat />
                            </GlobalModalProvider>
                          </GlobalChatProvider>
                        </GlobalChatTrackingProvider>
                      </Elements>
                    </DoptProvider>
                  </UserContextProvider>
                </ApolloProvider>
              </InitialSetup>
            </ReferrerProvider>
          </LocalStorageGlobalContextProvider>
        </DatadogProvider>
      </BugsnagProvider>
    </RouterProvider>
  )
}

interface InitialSetupProps {
  isLoggedIn: boolean
  children: React.ReactNode
}

const InitialSetup = ({ isLoggedIn, children }: InitialSetupProps) => {
  const { pathname } = useLocation()
  const [youtubeIframeScriptLoaded, setYoutubeIframeScriptLoaded] = useState(false)
  const isComponentMounted = useRef(false)
  const [urlChange] = useUrlChangeMutation({
    client: isLoggedIn ? privateApolloClient : publicApolloClient
  })

  // const referrer = useReferrer()
  useFlashMessage()
  const manualPageLoadTriggerRef = useRef<boolean>(false)

  const setManualPageLoadTrigger = (value: boolean) => {
    manualPageLoadTriggerRef.current = value
  }

  const referrer = useReferrer()
  // Function to trigger manual page load event
  const triggerPageLoadEvent = useCallback(() => {
    // account for Helmet title change
    setTimeout(() => {
      trackPage(referrer)
    }, 200)
  }, [referrer])

  // Track page views in Segment
  useEffect(() => {
    // async function sendUrlChange() {
    //   try {
    //     await urlChange({
    //       variables: {
    //         input: {
    //           fullUrl: window.location.href,
    //           referrer: referrer,
    //           search: new URLSearchParams(window.location.search).toString(),
    //           userAgent: window.navigator.userAgent
    //         }
    //       }
    //     })
    //   } catch (e) {
    //     // error is handle in mutation response
    //   }
    // }

    // if (!manualPageLoadTriggerRef.current) {
    //   triggerPageLoadEvent()
    // }

    // // track page visit on server for SPA route change, skipping initial load
    // if (!isAuthPath() && isComponentMounted.current) {
    //   sendUrlChange()
    // }

    isComponentMounted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, urlChange])

  // Loads Youtube Iframe API script which is used globally to render all Sanity embeded Youtube videos
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return
    }

    const script = document.createElement('script')
    script.src = 'https://www.youtube.com/iframe_api'
    script.id = 'youtube-iframe-api'

    document.body.appendChild(script)
  }, [])

  window.onYouTubeIframeAPIReady = () => {
    setYoutubeIframeScriptLoaded(true)
  }

  if (!isComponentMounted.current) return <Loading />

  return (
    <PageLoadContext.Provider
      value={{
        setManualPageLoadTrigger,
        triggerPageLoadEvent,
        youtubeIframeScriptLoaded
      }}
    >
      {children}
    </PageLoadContext.Provider>
  )
}

export default GlobalProviders
