import { useState } from 'react'

import { useTopBannerControls } from 'domains/Enrollment/hooks/useTopBannerControls'
import OnboardingModal from 'domains/Onboarding/OnboardingModal'

import { ErrorMessage } from 'components'
import Modal from 'components/Modal'
import SubscriptionExpiredModal from 'components/SubscriptionExpiredModal/SubscriptionExpiredModal'
import DunningBanner from 'components/banners/TopBanner/DunningBanner'
import EnrollmentBanner from 'components/banners/TopBanner/EnrollmentBanner'
import ExpiredBanner from 'components/banners/TopBanner/ExpiredBanner'
import NpsBanner, {
  getNPSBannerDismissalFlag
} from 'components/banners/TopBanner/NpsBanner'
import RenewalBanner from 'components/banners/TopBanner/RenewalBanner'
import TrialBanner from 'components/banners/TopBanner/TrialBanner'
import UpcomingExpirationBanner from 'components/banners/TopBanner/UpcomingExpirationBanner'

import { PLAN_INDIVIDUAL, PLAN_TEAM } from 'constants/planType'

import { useBannerDataQuery } from 'gql'

import { trackModalDismissed } from 'hooks/useModalTracking'

import {
  getIsRenewalBannerDismissed,
  getIsUpcomingExpirationBannerDismissed
} from 'utils/cookies'
import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { UpdateCompanyBanner } from './UpdateCompanyBanner'
import {
  getShouldShowRenewalBanner,
  shouldShowUpdateCompanyBanner
} from './topBannerHelpers'

const PAID_ROUTES = [
  '/concepts',
  '/projects',
  '/m',
  '/c',
  '/collections',
  '/directory',
  '/units',
  '/guides'
]

export default function TopBanner({ className }: { className?: string }) {
  const { loading, error, data } = useBannerDataQuery()
  // grab session cookie to see if an expired user has already dismissed the expiry modal
  const expiryModalDismissedCookie =
    Boolean(localStorage.getItem('expiry_modal_dismissed')) || false
  const [expiryModalDismissed, setExpiryModalDismissed] = useState(
    expiryModalDismissedCookie
  )

  const {
    currentUser: user,
    enrollmentSeason,
    preEnrollmentSeason,
    currentLivePrograms
  } = data || {}

  const { shouldShowEnrollmentBanner, userCanEnroll } = useTopBannerControls({
    user,
    enrollmentSeason,
    currentLivePrograms,
    preEnrollmentSeason
  })

  if (loading || !data) return null
  if (error) return <ErrorMessage error={error} />

  if (!user) {
    return null
  }

  const {
    profile,
    paymentMethods,
    subscriptions,
    shouldShowNpsSurvey,
    openInvoices,
    dismissedUpdateCompanyBannerAt,
    companyNameUpdatedAt,
    companyName,
    hasUpcomingCohort
  } = user

  const bannerCohort = user.cohortsShowingNpsSurvey && user.cohortsShowingNpsSurvey[0]
  const isBillingPage = window.location.pathname.startsWith('/billing')
  const { timezone } = profile
  const activeSubscription = subscriptions?.active
  const lastSubscription = subscriptions?.last
  const isCanceling = !!activeSubscription?.isCanceled
  const hasOpenInvoices = openInvoices.length > 0
  const isBilledViaInvoice = activeSubscription?.stripeCollectionMethod === 'send_invoice'
  const isDunning = !!activeSubscription?.isDunning
  const hasPaymentMethod = paymentMethods.length > 0

  const shouldShowRenewalBanner =
    !isBillingPage &&
    activeSubscription &&
    !user.is.trialing &&
    getShouldShowRenewalBanner({
      activeSubscription,
      isBilledViaInvoice,
      hasOpenInvoices,
      isCanceling,
      isRenewalBannerDismissed: getIsRenewalBannerDismissed(
        activeSubscription.expiresAt,
        activeSubscription.daysLeft
      )
    })

  const shouldShowUpcomingExpirationBanner =
    activeSubscription &&
    !getIsUpcomingExpirationBannerDismissed(
      activeSubscription.expiresAt,
      activeSubscription.daysLeft
    ) &&
    isCanceling &&
    !activeSubscription.isExpired &&
    activeSubscription.daysLeft <= 30 &&
    !isBillingPage &&
    user.is.trialing === false

  const shouldShowExpiredBanner = !isBillingPage && user.accessPolicyKind === 'expired'

  const shouldShowNpsBanner =
    shouldShowNpsSurvey &&
    !hasUpcomingCohort &&
    bannerCohort &&
    !shouldShowEnrollmentBanner &&
    window.localStorage.getItem(getNPSBannerDismissalFlag(bannerCohort.cohort.season)) !==
      '1'

  const handleExpiryModalClose = () => {
    // set local storage item so the user isn't always seeing the expiry modal
    localStorage.setItem('expiry_modal_dismissed', 'true')
    setExpiryModalDismissed(true)
    trackModalDismissed({
      modal_group: 'reactivation_flow',
      modal_name: 'subscription_expired'
    })
    // if on a paid page, redirect to the free user homepage
    if (PAID_ROUTES.includes(location.pathname)) {
      window.location.assign('/')
    }
  }
  const startPurchaseFlow = () => {
    // set local storage item so the user isn't always seeing the expiry modal
    localStorage.setItem('expiry_modal_dismissed', 'true')
    setExpiryModalDismissed(true)
    trackCtaClicked({
      cta_location: 'subscription_expired_modal',
      cta_type: 'button',
      text: 'View plans'
    })
    window.location.assign('/subscribe/choose-plan')
  }

  const renderBanners = () => {
    if (!isBillingPage && isDunning && activeSubscription.userIsOwner) {
      return (
        <DunningBanner
          isTeamSubscription={!!activeSubscription.isTeam}
          isSubscriptionOwner={activeSubscription.userIsOwner}
          hasPaymentMethod={hasPaymentMethod}
          expiresAt={activeSubscription.expiresAt}
          isDunning={activeSubscription.isDunning}
        />
      )
    }

    if (shouldShowRenewalBanner) {
      return (
        <RenewalBanner
          subscription={activeSubscription}
          isBilledViaInvoice={isBilledViaInvoice}
          hasOpenInvoices={hasOpenInvoices}
        />
      )
    }

    if (shouldShowUpcomingExpirationBanner) {
      return (
        <UpcomingExpirationBanner
          hasPaymentMethod={hasPaymentMethod}
          subscription={activeSubscription}
        />
      )
    }

    if (shouldShowExpiredBanner) {
      const subscription = activeSubscription || lastSubscription
      // We are deprecating the expired banner, except for on the account page. All other instances should use this modal.
      if (window.location.pathname.includes('/account')) {
        return (
          <ExpiredBanner
            // NOTE: Plan type is team when there is no subscription available because expired members
            //       without an associated subscription should be team members--non-subscription owners.
            planType={!subscription || subscription.isTeam ? PLAN_TEAM : PLAN_INDIVIDUAL}
            hasPaymentMethod={hasPaymentMethod}
            isSubscriptionOwner={!!subscription?.userIsOwner}
          />
        )
      }
      return (
        <>
          <Modal isOpen={!expiryModalDismissed} handleClose={handleExpiryModalClose}>
            <SubscriptionExpiredModal
              onClose={handleExpiryModalClose}
              onStartPurchaseFlow={startPurchaseFlow}
            />
          </Modal>
        </>
      )
    }

    if (shouldShowNpsBanner) {
      return (
        <NpsBanner
          page={window.location.pathname}
          liveProgramName={bannerCohort.cohort.cmsProgram.name}
          userId={user.id}
          season={bannerCohort.cohort.season}
        />
      )
    }

    if (user.is.paidMember) {
      if (enrollmentSeason && userCanEnroll) {
        const onboardingModal = <OnboardingModal />
        const enrollmentBanner = shouldShowEnrollmentBanner ? (
          <EnrollmentBanner
            key="enrollment-banner"
            enrollmentSeason={enrollmentSeason}
            userTimezone={timezone}
            userId={user.id}
          />
        ) : null
        if (enrollmentBanner || onboardingModal) {
          return (
            <div data-test="top-banner">
              {onboardingModal}
              {enrollmentBanner}
            </div>
          )
        }
      }
    }

    if (
      !subscriptions?.active &&
      !subscriptions?.last &&
      subscriptions?.trial &&
      subscriptions?.trial?.status !== 'converted'
    ) {
      return (
        <TrialBanner
          trialStatus={subscriptions?.trial?.status}
          daysLeftInTrial={subscriptions?.trial?.daysLeftInTrial}
        />
      )
    }

    if (
      user.is.member &&
      shouldShowUpdateCompanyBanner(dismissedUpdateCompanyBannerAt, companyNameUpdatedAt)
    ) {
      return <UpdateCompanyBanner companyName={companyName} />
    }
  }

  const banners = renderBanners()

  return (
    <>
      {banners ? (
        <div className={cn('mb-2', className)} data-test="top-banner">
          {banners}
        </div>
      ) : null}
    </>
  )
}
