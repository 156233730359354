import { privateApolloClient, publicApolloClient } from 'apolloClient'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useUrlChangeMutation } from 'gql'

import { trackPage } from 'utils/tracking/segment'
import { isAuthPath } from 'utils/url'

interface ReferrerContextProps {
  referrer: string
}

const ReferrerContext = createContext<ReferrerContextProps | undefined>(undefined)

interface ReferrerProviderProps {
  children: React.ReactNode
  isLoggedIn: boolean
}

export const ReferrerProvider = ({ children, isLoggedIn }: ReferrerProviderProps) => {
  const location = useLocation()
  const [referrer, setReferrer] = useState<string>(document.referrer || '')
  const prevLocation = useRef<string>('')

  const [urlChange] = useUrlChangeMutation({
    client: isLoggedIn ? privateApolloClient : publicApolloClient
  })

  useEffect(() => {
    const sendUrlChange = async (currentReferrer: string) => {
      try {
        await urlChange({
          variables: {
            input: {
              fullUrl: window.location.href,
              referrer: currentReferrer,
              search: new URLSearchParams(window.location.search).toString(),
              userAgent: window.navigator.userAgent
            }
          }
        })
      } catch (e) {
        // Handle error
      }
    }

    if (!prevLocation.current) {
      prevLocation.current = location.pathname
      return
    }

    const currentPath = location.pathname
    if (prevLocation.current !== currentPath) {
      const currentReferrer = window.location.origin + prevLocation.current
      setReferrer(currentReferrer)

      // Track page view
      if (isLoggedIn) {
        trackPage(currentReferrer)
      }

      // Call sendUrlChange for SPA navigation
      if (!isAuthPath()) {
        sendUrlChange(currentReferrer)
      }
    }

    prevLocation.current = currentPath
  }, [location, urlChange, isLoggedIn])

  return (
    <ReferrerContext.Provider value={{ referrer }}>{children}</ReferrerContext.Provider>
  )
}

export const useReferrer = (): string => {
  const context = useContext(ReferrerContext)
  if (!context) {
    throw new Error('useReferrer must be used within a ReferrerProvider')
  }
  return context.referrer
}
