import { useBlock } from '@dopt/react'
import RichText from '@dopt/react-rich-text'
import { ReactComponent as ArrowRightIcon } from 'arrow-right.svg'
import { ReactComponent as NewTabIcon } from 'p-new-tab.svg'
import { twMerge } from 'tailwind-merge'

import { trackCtaClicked } from 'utils/tracking/analytics'

interface IDoptPanel {
  blockIdentifier: string
  className?: string
  completeOnClick?: boolean
}

const DoptPanel = ({ blockIdentifier, className, completeOnClick }: IDoptPanel) => {
  const [block, transition] = useBlock<['complete']>(blockIdentifier)

  if (!block?.state.active) return null

  const anchorTarget = (block.field('cta-url') as string)?.startsWith('http')
    ? '_blank'
    : '_self'

  const onClick = () => {
    trackCtaClicked({
      cta_location: 'AI Chat - after response #3',
      cta_type: 'card',
      text: block.field('subheader') as string,
      destination: block.field('cta-url') as string
    })

    if (completeOnClick) {
      transition('complete')
    }
  }

  return (
    <a
      href={block.field('cta-url') as string}
      target={anchorTarget}
      rel="noreferrer"
      className={twMerge(
        'flex items-center rounded-md p-4 pr-6 transition-colors hover:no-underline',
        !block.field('background-color') && 'bg-rb-blue-50 hover:bg-[#CFD6FF]',
        className
      )}
      style={{ backgroundColor: block.field('background-color') as string }}
      onClick={onClick}
    >
      <div className="grow">
        <RichText className="text-md mb-2 flex items-center font-semibold text-[#090B0A] [&>p]:m-0 [&>p]:block [&>p]:leading-[19px] [&>div]:ml-2 [&>div]:mr-1 [&>img]:h-4 [&>img]:w-4">
          {block.field('header')}
        </RichText>

        <p className="mb-0 leading-[17px] text-[#090B0A]">{block.field('subheader')}</p>
      </div>
      {anchorTarget === '_blank' ? (
        <NewTabIcon className="h-5 w-5 text-[#090B0A]" />
      ) : (
        <ArrowRightIcon className="h-4 w-4 text-[#090B0A]" />
      )}
    </a>
  )
}

export default DoptPanel
